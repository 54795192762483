(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEmClientLicenseController", domainEmClientLicenseController);

	function domainEmClientLicenseController($scope, $rootScope, $http, $mdDialog, $q, $compile, $filter, $timeout, coreLicensing, errorHandling, successHandling, gridCheckboxes, NgTableParams, userDataService, coreDataDomainSettings) {

		var vm = this;

		vm.form = null;
		vm.edition = 1;
		vm.verificationState = sessionStorage["emClientVerificationRequested"] ? 1 : 0;
		vm.verificationCode = "";
		vm.requestInfo = {};
		vm.searchParams = {
			domain: vm.domain,
			skip: 0,
			take: 0,
			search: null,
			sortField: "date",
			sortDescending: true
		};
		vm.user = userDataService.user;
		vm.active = false;
		activate();
		
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.countrySearch = "";

		function queryData(params) {
			if (vm.verificationState !== 4 || !vm.active) return $q.when();
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = params.count();
			vm.searchParams.fullName = vm.requestInfo
				? vm.requestInfo.fullName || userDataService.user.displayName
				: userDataService.user.displayName;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
				break;
			}
			return $http
				.post('~/api/v1/settings/domain/emclient/activations', JSON.stringify(vm.searchParams))
				.then(function(success) {
						vm.searchResults = success.data.activations;
						vm.searchResultCount = success.data.totalCount;
						vm.activeCount = success.data.activeCount;
						vm.licenseInfo.availableActivations = success.data.activationDeviceCount;
						params.total(vm.searchResultCount);
						return vm.searchResults;
					},
					function(fail) {
						if (fail.data && fail.data.errorCode === 11) { // ActivationKeyNotFound
							errorHandling.report(fail.data);
							vm.verificationState = fail.data.state;
						}
					});
		}

		async function activate() {
			try {
				$rootScope.spinner.show();

				vm.tableParams = new NgTableParams(
					{ sorting: { activationState: 'asc' } },
					{ getData: queryData, counts: $rootScope.commonTableCounts }
				);

				vm.checkboxes = gridCheckboxes.init('activationId');
				vm.checkboxes.table = vm.tableParams;

				const successData = await coreDataDomainSettings.settingsData.domainData;
				vm.domainData = successData;
				recalculateLicense();
				coreLicensing.watchForChanges($scope, recalculateLicense);

				// Wait for verification status before refreshing
				await checkVerificationStatus();
				await vm.refresh();
			} catch (error) {
				errorHandling.report(error);
			} finally {
				$rootScope.spinner.hide();
			}
		}


		const refreshData = function() {
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function() {
				}, function() {});
		}
		vm.refresh = function() {
			refreshData();
		};

		const doVerifyCode = function(ev) {
			const fullName = vm.requestInfo
				? vm.requestInfo.fullName || userDataService.user.displayName
				: userDataService.user.displayName;
			$http
				.post('~/api/v1/settings/domain/emclient/verify-code',
					JSON.stringify({
						authCode: vm.verificationCode.toString().padStart(6, '0'),
						fullName: fullName
					}))
				.then(function(success) {
					switch(success.data.state || vm.verificationState) {
						case 3: // domain verified
							loadCountryCodes();
							break;
						case 4: // complete
							getActivationKeyInfo();
							break;
						}
					vm.verificationState = success.data.state;

				}, errorHandling.report);

		}
		const submitRequestOffer = function(ev) {
			const disableResendButton = function(duration) {

				vm.disableSendCode = true;
				$timeout(function() {
					vm.disableSendCode = false;
				}, duration);
			}
			const onRequestOfferFailure = function(fail) {
				if (fail.data && fail.data.errorCode) {
					vm.verificationState = 2;
					vm.serverRejectedReason = fail.data.message;
				} else {
					errorHandling.report(fail.message);
				}
			}
			$http
				.post('~/api/v1/settings/domain/emclient/request-offer')
				.then(function(success) {
					disableResendButton(10 * 1000);
					sessionStorage.emClientVerificationRequested = true;
					vm.verificationState = success.data.state;
					if (vm.verificationState === 3)
						vm.emClientServiceError = success.data.message;
					else {
						successHandling.report($filter("translate")("EMCLIENT_DOMAIN_VERIFICATION_EMAIL", { email: userDataService.user.emailAddress }));
					}

				}, onRequestOfferFailure);

		}
		vm.clearSearchTerm = function() {
			vm.countrySearch = "";
		};
		vm.filterCountrySearch = function(item) {
			return item.translation.toLowerCase().startsWith(vm.countrySearch.toLowerCase()) || item.key.toLowerCase().startsWith(vm.countrySearch.toLowerCase());
		}
		function loadCountryCodes() {
			$http.get("~/api/v1/settings/domain/country-codes")
				.then(onCountryCodesLoaded, errorHandling.report);
			function onCountryCodesLoaded(result) {
				vm.countries = [];
				angular.forEach(result.data.results, function (key) {
					vm.countries.push({ key: key, translation: $filter("translate")("COUNTRY_CODE_" + key) });
				});
			}
		}
		vm.registerEmClientActivationKey = function(ev, form) {
			if (vm.verificationState !== 3 || form.$invalid)
				return;

			$rootScope.spinner.show();

			$http
				.post('~/api/v1/settings/domain/emclient/request-license',
					JSON.stringify({
						organizationName: vm.organizationName,
						countryCode: vm.countryCode,
						fullName: vm.fullName,
						ownerEmail: vm.ownerEmail
					})
				)
				.then(function(success) {

						vm.verificationState = success.data.state;
						if (vm.verificationState === 0) {
							vm.requestInfo = {};
							delete sessionStorage["emClientRequestInfo"];
						}
						if (vm.verificationState === 4) {
							successHandling.report($filter("translate")("EMCLIENT_ACTIVATION_CONFIRMATION_MESSAGE"));
							vm.licenseInfo = success.data;
						}
					},
					onActivationKeyError)
				.finally(function() {
					$rootScope.spinner.hide();
					refreshData();
				});

		}

		vm.resendCode = _.debounce(submitRequestOffer, 500);
		vm.verifyCode = _.debounce(doVerifyCode, 250, true);

		vm.purchaseActivations = function() {
			if (vm.verificationState !== 4 || !vm.licenseInfo.activationKey) return;
			const baseUrl = debugMode ? "https://www-test.emclient.com" : "https://www.emclient.com";
			window.open(`${baseUrl}/add-seats.aspx?key=${vm.licenseInfo.activationKey}`, "_blank");

		}
		vm.changeActivationState = function(active) {
			var ids = vm.checkboxes.getCheckedItems();
			if (!ids || !angular.isArray(ids) || ids.length === 0)
				return;
			$http
				.post(`~/api/v1/settings/domain/emclient/set-activation-state/${active || false}`,
					JSON.stringify({ ints: ids }))
				.then(vm.refresh, errorHandling.report);
		}

		vm.deleteActivations = function() {
			var ids = vm.checkboxes.getCheckedItems();
			if (!ids || !angular.isArray(ids) || ids.length === 0)
				return;
			$http
				.post("~/api/v1/settings/domain/emclient/delete-activations",
					JSON.stringify({ ints: ids }))
				.then(vm.refresh, errorHandling.report);
		}
		vm.refreshSelectionStatus = function() {
			var ids = vm.checkboxes.getCheckedItems();
			if (!ids || !angular.isArray(ids) || ids.length === 0) {
				vm.hasInactiveSelected = false;
				vm.hasActiveSelected = false;
				return;
			} 
			const checkedItems = vm.searchResults.filter(x => ids.includes(x.activationId.toString()));
			vm.hasInactiveSelected = checkedItems && checkedItems.some(item => item.activationState === "Inactive");
			vm.hasActiveSelected = checkedItems && checkedItems.some(item => item.activationState === "Active");

		}

		
		const checkEmClientEligibility = function() {
			return coreLicensing.edition < 2 &&
				coreLicensing.license &&
				!coreLicensing.license.isTrialLicense && 
				vm.domainData.emClientOffersAvailable &&
				vm.domainData.isTopLevelDomain;
		}
		function recalculateLicense() {
			vm.edition = coreLicensing.edition;
			vm.isEligibleEmClientLicenses = checkEmClientEligibility();

		}
		function checkVerificationStatus() {
			return $http.get('~/api/v1/settings/domain/emclient/verification-status')
				.then(function(success) {
					vm.verificationState = success.data.state;
					if (success.data.state >= 3)
						getActivationKeyInfo();
					if (vm.verificationState === 0 && sessionStorage["emClientVerificationRequested"])
						vm.verificationState = 1;
				})
				.catch(errorHandling.report)
				.finally(function() {
					vm.active = true;
				});
		}
		
		vm.showEmClientIntro = async function () {
			try {
				const parentElement = angular.element(document.querySelector('#domain-emclient-license'))[0];
				const success = await $mdDialog.show({
					controller: "domainOfferEmClientNotifyController",
					controllerAs: "ctrl",
					templateUrl: "app/root/modals/domain-offer-emclient-notify.dlg.html",
					clickOutsideToClose: true,
				});
				if (success) {
					console.log("Dialog Response" + JSON.stringify(success));
				}

			} catch(err) {
				//
			}
		}

		function onActivationKeyError(error) {
			errorHandling.report(error.data.message);
			vm.emClientServiceError = error.data.message;
			if (error.data.message === "EMCLIENT_ERROR_INVALID_VERIFICATION_API_KEY") {
				vm.verificationState = 0;
			}
		}
		function getActivationKeyInfo() {
			if (vm.active) $rootScope.spinner.show();
			$http
				.get('~/api/v1/settings/domain/emclient/license-info')
				.then(function(success) {
						switch (success.data.state) {
						case 3: // domain verified
							loadCountryCodes();
							break;
						case 4:
							vm.licenseInfo = success.data;
							break;
						}
						vm.verificationState = success.data.state;
					},
					onActivationKeyError)
				.finally(function() {
					if (vm.active)
						$rootScope.spinner.hide();
				});
		}


		vm.copyActivationKeyToClipboard = function () {
			copyToClipboard(vm.licenseInfo.activationKey);
		}
		function copyToClipboard(value) {
			value = value || "";
			if (window.isSecureContext && navigator.clipboard) {
				navigator.clipboard
					.writeText(value)
					.then(() => successHandling.report("COPIED_TO_CLIPBOARD"));
			} else {

				const textArea = document.createElement("textarea");
				textArea.value = value;
				document.body.appendChild(textArea);
				textArea.focus();
				textArea.select();
				document.execCommand("copy");
				document.body.removeChild(textArea);
				successHandling.report("COPIED_TO_CLIPBOARD");
			}
		}
		function contextMenuGridItem(item, ev) {
			var showMenu = true;
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			var items = vm.checkboxes.getCheckedItems();

			if (items.length > 0 && !items.some(val => val === item.activationId.toString()) || items.length === 0) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.activationId];
			}
			vm.refreshSelectionStatus();
			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'activate', disabled: !vm.hasInactiveSelected, click: () => vm.changeActivationState(true), translateKey: 'REACTIVATE' },
				{ key: 'deactivate', disabled: !vm.hasActiveSelected, click: () => vm.changeActivationState(false), translateKey: 'DEACTIVATE' },
//				{ key: 'delete', click: () => vm.deleteActivations(), translateKey: 'DELETE' },
			];

			var selected = {};
			for (var i = 0; i < items.length; ++i) {
				selected[items[i]] = true;
			}

			if (showMenu) {
				var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
				var element = $("#context-menu-area");
				if (element) {
					var elementCompiled = $compile(elementToCompile)($scope);
					element.append(elementCompiled);
				}
			}
		}
	}

})();