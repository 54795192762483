(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSpoolManageWaitingController", adminSpoolManageWaitingController);

	function adminSpoolManageWaitingController($scope, $filter, $mdDialog, $rootScope, $http, $localStorage, $compile, 
		errorHandling, successHandling, coreDataSpool, coreDataSysAdminSettings, coreData, NgTableParams, gridCheckboxes, popupService, $translate, coreDataFileStorage, $timeout, i18n) {
		var vm = this;
		$scope.spoolCore = coreDataSpool;
		$scope.gridColumns = [
			{ sortField: 'fileName', translate: 'FILENAME' },
			{ sortField: 'shortSpool', translate: 'SPOOL_PATH' },
			{ sortField: 'from', translate: 'SENDER' },
			{ sortField: 'deliveredratio', translate: 'RECIPIENTS' },
			{ sortField: 'size', translate: 'SIZE' },
			{ sortField: 'deliveryAttempts', translate: 'ATTEMPTS' },
			{ sortField: 'timeTranslate', translate: 'SPOOL_TIME_IN_SPOOL' },
			{ sortField: 'priority', translate: 'PRIORITY' },
			{ sortField: 'statusStr', translate: 'STATUS' },
			{ sortField: 'nextDeliveryAttempt', translate: 'NEXT_ATTEMPT' }
		];
		$scope.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/spool-messages";
		vm.searchParams = { spoolInput: [{ startIndex: 0, count: 0, sortType: null, search: '', ascending: false, filter: 'waiting' }] };
		vm.selectedSection = $localStorage.spoolSection ? $localStorage.spoolSection : 'WAITING_TO_DELIVER';
		vm.tableParams = new NgTableParams(
			{
				sorting: { 2: 'asc' },
				count: 100
			}, {
				getData: queryData,
				localStorageKey: "ngTableRows-Spool"
			});

		vm.checkboxes = gridCheckboxes.init("fileName");
		vm.checkboxes.table = vm.tableParams;
		vm.spoolStatus = coreDataSpool.spoolStatus;
		vm.recipientStatus = coreDataSpool.recipientStatus;
		vm.sortFieldTypes = coreDataSpool.sortFieldTypes;

		// Functions
		vm.forceMessage = forceMessage;
		vm.resetRetries = resetRetries;
		vm.setPriority = setPriority;
		vm.deleteMessages = deleteMessages;
		vm.viewMessage = viewMessage;
		vm.moveMessages = moveMessages;
		vm.downloadMessages = downloadMessages;
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyToClipboard = copyToClipboard;


		/////////////////////
		function forceMessage() {
			var toForce = [];
			for (const key in vm.checkboxes.items) {
				if (Object.prototype.hasOwnProperty.call(vm.checkboxes.items, key)) {
					if (vm.checkboxes.items[key]) {
						toForce = toForce.concat($.grep(vm.searchResults, function(r) { return r.fileName === key }));
					}
				}
			}
			if (toForce.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			} else if (toForce.length == 1) {
				doForceMessage();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: toForce.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(doForceMessage, function () {});
			}

			function doForceMessage() {
				coreDataSpool.forceMessage(toForce)
					.then(function () {
						$scope.$emit('reloadSpool');
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function resetRetries() {
			var toReset = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toReset = toReset.concat($.grep(vm.searchResults, function (r) { return r.fileName === key }));
				}
			}
			if (toReset.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			} else if (toReset.length == 1) {
				doResetRetries();
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent($filter('translate')('CONFIRMATIONS_MULTI_GENERIC', { count: toReset.length }))
					.ok($filter('translate')('OK'))
					.title($filter('translate')('CONFIRMATION_REQUIRED'));
				$mdDialog.show(confirm).then(function () { doResetRetries(); });
			}

			function doResetRetries() {
				coreDataSpool.refreshRetries(toReset)
					.then(function (success) {
						$scope.$emit('reloadSpool');
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function setPriority(ev) {
			var toSet = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toSet = toSet.concat($.grep(vm.searchResults, function (r) { return r.fileName === key }));
				}
			}
			if (toSet.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}

			$mdDialog.show({
				locals: {
					count: toSet.length
				},
				controller: "changeSpoolPriority",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/change-spool-priority.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					coreDataSpool.setPriority(modalSuccess, toSet)
						.then(function (success) {
							$scope.$emit('reloadSpool');
							vm.checkboxes.reset();
							vm.tableParams.reload();
							successHandling.report();
						}, errorHandling.report);
				}, function (cancel) {

				});
		}

		function showRecipients(ev) {
			var params = JSON.stringify({ spoolInput: [{ fileName: ev.currentTarget.childNodes[4].innerText.replace('.eml', ''), spoolName: coreDataSysAdminSettings.settings.globalMailSettings.pathSettings.spoolPath }] });
			$http.post('~/api/v1/settings/sysadmin/spool-message-recipients', params)
				.then(function (success) {
					$mdDialog.show({
						locals: {
							recipients: success.data.spoolMessageRecipients,
							statuses: { 0: 'COMPLETED', 1: 'PENDING' }
						},
						controller: "spoolViewRecipientsController",
						controllerAs: "spReCtrl",
						templateUrl: "app/sysadmin/manage/modals/admin-spool-view-recipients.dlg.html",
						targetEvent: ev
					}).then(function (modalSuccess) { }, function (modalCancel) { });
				}, function (failure) {
					errorMessageService.showErrorMessage(failure);
				});
		}

		function deleteMessages(params) {
			var toDelete = [];
			var senderEmailAddress = undefined;
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var result = vm.searchResults.find(function (r) { return r.fileName === key });
					if (result) toDelete = toDelete.concat(result);
				}
			}
			if (toDelete.length === 0 && !params.all) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}
			else if (toDelete.length > 0 && params.all && toDelete.every(x => toDelete[0].from == x.from)) {
				senderEmailAddress = toDelete[0].from;
			}
			if (params.all) {
				$mdDialog.show({
					locals: {
						address: senderEmailAddress,
						type: 2,
						count: 0,
						editAddress: params.all,
					},
					controller: "spoolDeleteConfirmController",
					controllerAs: "ctrl",
					templateUrl: "app/sysadmin/manage/modals/admin-spool-delete-confirm.dlg.html",
					targetEvent: params.ev
				})
					.then(function (modalSuccess) {
						var apiParams = JSON.stringify({ spoolInput: [{ type: coreDataSpool.spoolStatTypes.OutboundSenders, search: modalSuccess.address, filter: 'waiting' },
																{ type: coreDataSpool.spoolStatTypes.InboundSenders, search: modalSuccess.address, filter: 'waiting' }] });

						var apiUrl = "~/api/v1/settings/sysadmin/spool-delete-messages/";
						$http.post(apiUrl, apiParams)
							.then(onSpoolActionSuccess, errorHandling.report);
					}, function () {
						return;
					});
			} else {
				var confirm = $mdDialog.confirmDeletion()
					.textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: toDelete.length }))
					.targetEvent(params.ev);
				$mdDialog.show(confirm).then(function (modalSuccess) {
					coreDataSpool.deleteMessages(toDelete)
						.then(onSpoolActionSuccess, errorHandling.report);
				}, function () {

				});
			}
		}
		function onSpoolActionSuccess() {
			$scope.$emit("reloadSpool");
			vm.checkboxes.reset();
			vm.tableParams.reload();
			successHandling.report();
		}

		function moveMessages(params) {
			var toMove = [];
			var senderEmailAddress = undefined;
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var result = vm.searchResults.find(function (r) { return r.fileName === key });
					if (result) {
						toMove = toMove.concat(result);
					}
				}
			}
			if (toMove.length === 0 && !params.all) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}
			else if (toMove.length > 0 && params.all && toMove.every(x => toMove[0].from == x.from)) {
				senderEmailAddress = toMove[0].from;
			}

			var folderName = "MovedSpoolItems";
			if (window.stOS === 'W')
				folderName += "\\";
			else
				folderName += "/";

			$mdDialog.show({
				locals: {
					spoolPath: coreDataSysAdminSettings.settings.globalMailSettings.pathSettings.spoolPath + folderName,
					address: senderEmailAddress,
					type: params.all ? 2 : 6,
					count: toMove.length,
					editAddress: params.all,
				},
				controller: "spoolMoveConfirmController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/admin-spool-move-confirm.dlg.html",
				targetEvent: params.ev
			})
				.then(function (modalSuccess) {
					var apiUrl, apiParams; 
					if (params.all) {
						apiParams = JSON.stringify({ spoolInput: [{ type: coreDataSpool.spoolStatTypes.OutboundSenders, moveLocation: modalSuccess.location, search: modalSuccess.address, filter: 'waiting' },
															   { type: coreDataSpool.spoolStatTypes.InboundSenders, moveLocation: modalSuccess.location, search: modalSuccess.address, filter: 'waiting' }] });
						apiUrl = "~/api/v1/settings/sysadmin/move-spool-messages/";
					} else {
						var moveInput = [];
						apiUrl ="~/api/v1/settings/sysadmin/move-selected-spool-messages/";
						for (var i = 0; i < toMove.length; ++i) {
							moveInput.push({ fileName: toMove[i].fileName.replace(".eml", ""), spoolName: toMove[i].spoolName });
						}
						apiParams = JSON.stringify({ spoolInput: moveInput, moveLocation: modalSuccess.location });

					}
					$http.post(apiUrl, apiParams)
						.then(onSpoolActionSuccess, errorHandling.report);
				}, function () {
					return;
				});
		}

		function viewMessage(ev, row) {
			var windowHref = coreDataSpool.getViewMessage(coreData.baseUrl, row);
			window.open(windowHref, $filter('translate')('SPOOL_VIEW_RECIPIENTS'), 'resizable=1, ' + popupService.dimensions.email);
		}

		function downloadMessages(ev, rows) {
			var toDownload = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					toDownload = toDownload.concat($.grep(vm.searchResults, function (r) { return r.fileName === key }));
				}
			}
			var input = [];
			toDownload.forEach(x => input.push({ fileName: x.fileName, spoolName: x.spoolName }));

			if (input.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
			}
			var fileName = input.length === 1 ? input[0].fileName : i18n.reactPluralize("FILENAME_MESSAGES_WITH_COUNT", { count: input.length }) + ".zip";
			var progressTimeout = $timeout(function () {
					var progress = $mdDialog.stProgressBar()
						.title($translate.instant("DOWNLOADING"))
						.barText("")
						.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
						.showCancel(false)
						.enableButtons(true)
						.autoClose(true)
						.percentProgress(-1)
						.guid("eml-download")
						.progressListener("eml-download.finished");
					$mdDialog.show(progress);
				},
				1000);

			var params = JSON.stringify({ spoolInput: input });
			coreDataFileStorage.downloadFile("~/api/v1/settings/sysadmin/spool-messages-export/", fileName, params)
				.then(function () {
					},
					function (failure) {
						errorHandling.report(failure);
					}).finally(function () {
					$rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
					if (progressTimeout) $timeout.cancel(progressTimeout);
				});

		}
		function Refresh() {
			$scope.$emit('reloadSpool');
			vm.checkboxes.reset()
			vm.tableParams
				.reload()
				.then(function () { }, function () { })
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.spoolInput[0].search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function queryData(params) {
			vm.searchParams.spoolInput[0].startIndex = (params.page() - 1) * params.count();
			vm.searchParams.spoolInput[0].count = params.count();
			vm.searchParams.spoolInput[0].sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.spoolInput[0].sortType = k;
				vm.searchParams.spoolInput[0].ascending = params.sorting()[k] !== 'desc';
				break;
			}

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.spoolListData[0].messages;
				vm.searchResultCount = result.data.spoolListData[0].messageCount;
				angular.forEach(vm.searchResults, function (message) {
					var time = moment.duration(message.timeinspool);
					message.timeHours = Math.floor(time.asHours());
					message.timeMinutes = Math.floor(time.asMinutes() - (message.timeHours * 60));
					message.timeSeconds = Math.floor(time.asSeconds() - ((message.timeMinutes + message.timeHours * 60) * 60));
					message.timeTranslate = $filter('translate')(message.timeHours ? 'SPOOL_TIME_HOURS' : 'SPOOL_TIME_NO_HOURS', { 
						hours: message.timeHours, minutes: message.timeMinutes, seconds: message.timeSeconds });
					if (message.status === 7) {
						var quTime = moment.duration(message.timeinquarantine);
						message.timeQuHours = Math.floor(quTime.asHours());
						message.timeQuMinutes = Math.floor(quTime.asMinutes() - (message.timeQuHours * 60));
						message.timeQuSeconds = Math.floor(quTime.asSeconds() - ((message.timeQuMinutes + message.timeQuHours * 60) * 60));
						message.timeQuTranslate = $filter('translate')(message.timeQuHours ? 'SPOOL_TIME_HOURS' : 'SPOOL_TIME_NO_HOURS', { 
							hours: message.timeQuHours, minutes: message.timeQuMinutes, seconds: message.timeQuSeconds });
						message.timeuntilremoval = new Date(message.timeuntilremoval);
					}
					if (message.status === 5) {
						if (message.pending === 0) {
							message.statusStr = 'DELIVERED';
						} else {
							message.statusStr = message.isThrottled ? 'THROTTLED' : 'WAITING_TO_DELIVER';
						}
					} else {
						message.statusStr = vm.spoolStatus[message.status];
					}
					message.statusStr = $filter('translate')(message.statusStr);
					message.creationDate = new Date(message.creationDate);
					message.fileName = message.fileName + ".eml";
				});
				params.total(vm.searchResultCount);
				
				return vm.searchResults;
			}
		}

		window.refreshSpool = function () {
			vm.checkboxes.reset();
			vm.tableParams.reload();
			$scope.$emit('reloadSpool');
		};

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.fileName; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.fileName];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "forceMessage", click: forceMessage, translateKey: "SPOOL_FORCE" },
				{ key: "resetRetries", click: resetRetries, translateKey: "SPOOL_RESET_RETRIES" },
				{ key: "setPriority", click: setPriority, params: $scope.dropdownEvent, translateKey: "CHANGE_PRIORITY" },
				{ key: "moveMessages", click: moveMessages, params: { ev: $scope.dropdownEvent, all: false } , translateKey: "SPOOL_MOVE_MESSAGES" },
				{ key: 'downloadMessages', click: downloadMessages,  params: $scope.dropdownEvent, translateKey: "DOWNLOAD_EML" },
				{ key: "deleteMessages", click: deleteMessages, params: { ev: $scope.dropdownEvent, all: false }, translateKey: "DELETE" },
				{ divider: true },
				{ key: "deleteAllMessages", click: deleteMessages,  params: { ev: $scope.dropdownEvent, all: true }, translateKey: "DELETE_ALL_FROM_SENDER" },
				{ key: "moveAllMessages", click: moveMessages,  params: { ev: $scope.dropdownEvent, all: true }, translateKey: "SPOOL_MOVE_ALL_SENDER_MESSAGES" },
				{ divider: true },
				{ key: "showRecipients", click: showRecipients, params: $scope.dropdownEvent, translateKey: "SPOOL_VIEW_RECIPIENTS" },
				{ key: 'copyPath', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.spoolName + "/" + item.fileName }, translateKey: "COPY_PATH" },
				{ key: 'copySender', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.from }, translateKey: "COPY_SENDER" },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
