(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsSystemAdminController", adminSettingsSystemAdminController);

	function adminSettingsSystemAdminController($rootScope, $scope, $filter, $mdDialog, $state, $http, $compile,
		coreDataSysAdminSettings, NgTableParams, gridCheckboxes, errorHandling, claimsService, i18n) {

		var vm = this;
		vm.admins = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { username: "asc" },
				count: 25
			}, {
			dataset: [],
			counts: $rootScope.commonTableCounts
		});
		vm.isReadOnly = !claimsService.isPrimarySysAdmin();
		vm.checkboxes = gridCheckboxes.init("username");
		vm.checkboxes.ignoreItemDelegate = function (item) { return item.isPrimaryAdmin || vm.isReadOnly; };
		vm.checkboxes.table = vm.tableParams;
		vm.myUsername = claimsService.getUsername();
		vm.initialized = false;

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;

		activate();

		/////////////////////

		function activate() {
			coreDataSysAdminSettings
				.init()
				.then(loadAdmins, errorHandling.report);
		}

		function stringCompare(strA, strB) {
			return strA.toUpperCase() === strB.toUpperCase();
		}

		function loadAdmins() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/logins")
				.then(onLoaded, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onLoaded(result) {
				vm.admins = result.data.systemLogins || [];
				angular.forEach(vm.admins, function (admin) {
					admin.sortIsPrimaryAdmin = String(admin.isPrimaryAdmin);
					admin.sortManageAdmins = String(admin.manageAdmins);
					admin.sortEnableIpAccessRestriction = String(admin.enableIpAccessRestriction);
					admin.sortDescription = admin.isPrimaryAdmin ? $filter("translate")("PRIMARY_ADMINISTRATOR") : String(admin.description || "");
					admin.sortEnabled = String(admin.enabled);
					admin.sortAge = Number(admin.ageDays);
				});
				vm.tableParams.settings({ dataset: vm.admins });
				refreshTable();
				vm.initialized = true;

				var admin = $.grep(vm.admins, function (admin) { return stringCompare(admin.username, claimsService.getUsername()); })[0];
				vm.isReadOnly = admin ? !admin.manageAdmins : true;
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function searchItems() {
			vm.checkboxes.reset();
			vm.tableParams.filter({ $: vm.searchText });
			vm.tableParams.reload();
		}

		function newItem() {
			$state.go("index.sysadmin-settings.system-administrators.specific", { username: "new" });
		}

		function editItem(card) {
			if (!card) return;
			$state.go("index.sysadmin-settings.system-administrators.specific", { username: card.username });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			if (vm.checkboxes.ignoreItemDelegate(item) || vm.isReadOnly) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.username; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.username];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteItems", click: deleteItems, params: $scope.dropdownEvent, translateKey: "DELETE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards)
				return;
			if (!angular.isArray(cards))
				return;

			var ids = [];
			for (var i = 0; i < cards.length; ++i) {
				if (!cards[i]) continue;
				if (cards[i] === claimsService.getUsername()) continue;
				var adminToDelete = $.grep(vm.admins, function (admin) { return !admin.isPrimarySysAdmin && stringCompare(admin.username, cards[i]); })[0];
				if (adminToDelete)
					ids.push(adminToDelete.id);
			}
			if (ids.length === 0) return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: ids.length }))
				.targetEvent(event);
			$mdDialog
				.show(confirm)
				.then(doDelete, function () { });

			function doDelete() {
				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/logins-delete", JSON.stringify(ids))
					.then(onDeleted, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDeleted() {
				if (vm.checkboxes.items[vm.myUsername]) {
					var confirm = $mdDialog.confirmDeletion()
						.title()
						.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE_ACCOUNTS"))
						.ok($filter("translate")("OK"))
						.noWarn(true)
						.hideCancel(true);
					$mdDialog.show(confirm);
				}
				loadAdmins();
			}
		}
	}

})();
