(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("messageAchiveSectionController", messageAchiveSectionController);

    function messageAchiveSectionController($rootScope, $scope, $http, $timeout, $filter, $mdDialog, $translate, $compile, $q, $state, coreDataDomainSettings,
        coreDataLicensing, coreLicensing, coreDataFileStorage, userDataService, errorHandling, successHandling, popupService, NgTableParams, gridCheckboxes,
        coreData, authStorage, emailNavigation, claimsService, i18n) {

        var vm = this;
        const domainState = $state &&
            $state.$current &&
            $state.$current.name &&
            $state.$current.name.toLowerCase().startsWith("index.sysadmin-manage.dashboard.domain-specific");
        vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;
        vm.isSysAdmin = userDataService.user.isSysAdmin && !domainState;
        vm.archiveType = vm.isSysAdmin ? "sysadmin" : "domain";

        vm.searchParams = { skip: 0, take: 0, search: null, sortField: 'progress', sortDescending: true };
        vm.defenderWarningAction = function () { window.open("http://portal.smartertools.com/kb/a3249/add-exceptions-to-windows-defender.aspx"); }
        vm.tableParams = new NgTableParams(
            {
                sorting: { progress: "desc" },
                count: 25
            }, {
            getData: queryData,
            counts: $rootScope.commonTableCounts
        });
        vm.checkboxes = gridCheckboxes.init("id");
        vm.checkboxes.table = vm.tableParams;
        vm.archiveSearches = [];
        vm.selectStatusMessage = getStatusMessage();
        vm.searchText = "";
        // Functions

        vm.contextMenuGridItem = contextMenuGridItem;
        vm.deleteItems = deleteItems;
        vm.editItem = editItem;
        vm.newSearch = newSearch;
        vm.editSearch = editSearch;
        vm.viewSearchResults = viewSearchResults;
        vm.searchArgsToSummaryText = searchArgsToSummaryText;
        vm.searchItems = searchItems;
        vm.refresh = refresh;
        $scope.$on("licensingChanged", onLicensingChanged);
        activate();

        ////////////////////////////////

        function activate() {
            vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;
            if (!vm.isEnterprise)
                return;

            $rootScope.$on("signalR.mailHub.client.messageArchiveSearchUpdate", function (e, searchGuid) {
                if (vm.searchResults && vm.searchResults.some(res => res.id == searchGuid)) {
                    refresh();
                }
            });

        }
        function refresh() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }
        function deleteItems(selectedCard, ev) {
            var ids = [];
            if (vm.checkboxes.items) {
                for (var key in vm.checkboxes.items) {
                    ids.push(key);
                }
            }
            if (ids.length === 0) {
                errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED"); return;
            }
            var confirm = $mdDialog.confirmDeletion()
                .textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: ids.length }))
                .targetEvent(ev);

            $mdDialog
                .show(confirm)
                .then(onConfirm);

            function onConfirm() {
                $http.post("~/api/v1/settings/domain/remove-list-searches", { input: ids })
                    .then(function (success) {
                        if (success.data)
                            successHandling.report();
                        else
                            errorHandling.warn($translate.instant("WARN_SOME_ITEMS_NOT_DELETED"));
                    }, errorHandling.report)
                    .finally(() => refresh());
            }
        }


        function editItem(selectedCard, ev) {
            const packet = emailNavigation.makeArchivePacket(vm.searchGuid, selectedCard.id);
            const url = emailNavigation.getPopoutUrl(packet);
            // stSiteRoot + "interface/root#/popout/email/"
            var newWindow = window.open(url, "emailID" + selectedCard.id, "resizable=1, " + popupService.dimensions.email);
        }

        function viewSearchResults(item, ev) {

            $state.go(".search", { searchGuid: item.id });

        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = vm.checkboxes.getCheckedItems();
            if ((items.length > 1 && !_.some(items, function (val) { return val == item.id; })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.id];
            }
            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "copyToMailbox", click: deleteItems, params: $scope.dropdownEvent, translateKey: "DELETE" },
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function editSearch(item, ev) {
            $mdDialog.show({
                locals: {
                    searchGuid: item.id,
                    isNew: false,
                },
                controller: "messageArchiveEditDialogController",
                controllerAs: "ctrl",
                skipHide: true,
                templateUrl: "app/settings/shared/message-archiving/modals/message-archive-edit.dlg.html",
                targetEvent: ev
            })
                .then(function (modalSuccess) {
                    refresh();
                }, function () { });
        }
        function queryData(params) {
            vm.searchParams.startIndex = (params.page() - 1) * params.count();
            vm.searchParams.count = params.count();
            vm.searchParams.includeErrored = true;

            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortType = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            $rootScope.spinner.show();
            return $http
                .post(`/api/v1/settings/${vm.archiveType}/message-archive-list-searches`, JSON.stringify(vm.searchParams))
                .then(onSearchSuccess, function () { })
                .finally($rootScope.spinner.hide);

            function onSearchSuccess(result) {
                vm.searchResults = result.data.results;

                vm.searchResultCount = result.data.totalCount;
                params.total(vm.searchResultCount);

                return vm.searchResults;
            }

        }

        function searchArgsToSummaryText(searchArgs) {

            const andy = $translate.instant("AND").toLocaleLowerCase();
            const suffix = $translate.instant("BETWEEN_X_AND_Y", {
                x: $filter("date")(searchArgs.startDateUtc, "shortDate"),
                y: $filter("date")(searchArgs.endDateUtc, "shortDate")
            });
            const sentByClause = searchArgs.to ? `${$translate.instant("SENT_TO")}: ${searchArgs.to}` : "";
            const sentFromClause = searchArgs.from ? `${$translate.instant("SENT_FROM")}: ${searchArgs.from}` : "";
            const senderClause = sentByClause && sentFromClause
                ? `${sentByClause} ${andy} ${sentFromClause}`
                : sentByClause
                    ? sentByClause
                    : sentFromClause
                        ? sentFromClause
                        : "";

            const subjectClause = searchArgs.subject
                ? $translate.instant('SUBJECT_CONTAINS_X', { x: searchArgs.subject })
                : "";
            const matchClause = subjectClause && senderClause ? `${$translate.instant("WHERE").toLocaleLowerCase()} ${subjectClause} ${senderClause}`
                : subjectClause ? `${$translate.instant("WHERE").toLocaleLowerCase()} ${subjectClause}`
                    : senderClause ? ` ${senderClause}`
                        : "";
            return matchClause && suffix
                ? `${$translate.instant("MESSAGES")} ${$filter("htmlUnescape")(matchClause)} ${suffix}`
                : `${$translate.instant("ALL")} ${$translate.instant("MESSAGES").toLocaleLowerCase()} ${suffix}`;
        }


        function newSearch(ev) {

            $mdDialog.show({
                locals: {
                    isNew: true,
                    searchGuid: ""
                },
                controller: "messageArchiveEditDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/settings/shared/message-archiving/modals/message-archive-edit.dlg.html",
                targetEvent: ev,
            })
                .then(function (modalSuccess) {
                    $state.go(".search", { searchGuid: modalSuccess.searchGuid });
                }, function () { });
        }


        function onSelectionCountChanged(ev, data) {
            vm.selectStatusMessage = getStatusMessage(data);
        }
        function getStatusMessage(length) {
            if (length && length > 0) {
                return $filter('translate')("YOU_HAVE_MESSAGES_SELECTED", { count: length });
            }
            return $filter("translate")("NO_MESSAGE_SELECTED");
        }

        function onLicensingChanged() {
            coreDataLicensing
                .init()
                .then(activate, errorHandling.report);
        }
    }

})();
