(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("adminSettingsSystemMessagesController", adminSettingsSystemMessagesController);

    function adminSettingsSystemMessagesController($rootScope, $scope, $mdDialog, $compile, $q, coreDataSysAdminSettings,
        NgTableParams, gridCheckboxes, $translate, $http, errorHandling, $state, claimsService, i18n) {

        var vm = this;

        vm.systemMessages = [];
        vm.tableParams = new NgTableParams(
            {
                sorting: { translated: 'asc' },
                count: 100
            }, {
            dataset: [],
            counts: $rootScope.commonTableCounts
        });
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.specialKey = function (item) { return item.key + "&&" + item.localeId; };
        vm.checkboxes.ignoreItemDelegate = function (item) { return item.localeId === "en"; };
        vm.checkboxes.table = vm.tableParams;

        // Functions
        vm.addMessage = addMessage;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.deleteItems = deleteItems;
        vm.editItem = editItem;
        vm.searchItems = function () { vm.tableParams.reload(); }

        activate();

        //////////////////////////

        function activate() {
            loadItems();
            $rootScope.$on('$translateChangeSuccess', function () {
                if (!vm.systemMessages)
                    return;
                angular.forEach(vm.systemMessages,
                    function (value) {
                        value.translated = $translate.instant('SYSTEM_MESSAGES_MESSAGE_TYPES_' + value.key.toUpperCase());
                    });
            });
        }

        function addMessage(ev) {
            $mdDialog.show({
                locals: {
                    sysMessages: vm.systemMessages
                },
                controller: "systemMessagesNewController",
                controllerAs: "addModCtrl",
                templateUrl: "app/sysadmin/settings/system-messages/modals/system-messages-new.dlg.html",
                targetEvent: ev
            })
                .then(onAddComplete, function () { });

            function onAddComplete(result) {
                $state.go("index.sysadmin-settings.system-messages.specific", { id: result.type, locale: result.localeId, isNew: true });
            }
        }

        function deleteItems(ev) {
            var items = vm.checkboxes.getCheckedItems();
            var count = items.length;
            if (!count) {
                errorHandling.warn("NO_MESSAGES_SELECTED");
                return;
            }

            var confirm = $mdDialog.confirmDeletion()
                .textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: count }))
                .targetEvent(ev);

            $mdDialog
                .show(confirm)
                .then(onConfirm);

            function onConfirm() {
                $rootScope.spinner.show();
                var params = { messages: [] };
                for (var i = 0; i < items.length; i++) {
                    var split = items[i].split("&&");
                    if (split.length !== 2)
                        continue;
                    params.messages.push({ key: split[0], localeId: split[1] });
                }
                $http.post("~/api/v1/settings/sysadmin/system-messages-delete", params)
                    .then(
                        function () {
                            loadItems();
                        },
                        errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function editItem(message, ev) {
            $state.go("index.sysadmin-settings.system-messages.specific", { id: message.key, locale: message.localeId });
        }

        function loadItems() {
            $http.get("~/api/v1/settings/sysadmin/system-messages")
                .then(onLoaded, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onLoaded(results) {
                vm.systemMessages = results.data.systemMessages || [];
                angular.forEach(vm.systemMessages,
                    function (value) {
                        value.translated = $translate.instant('SYSTEM_MESSAGES_MESSAGE_TYPES_' + value.key.toUpperCase());
                        value.language = getLanguageName(value.localeId);
                    });

                vm.tableParams.settings({ dataset: vm.systemMessages });
                refreshTable();
            }
        }

        function refreshTable() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();
            if (item.localeId == 'en')
                return;
            var items = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val === vm.checkboxes.specialKey(item); })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'deleteCheckedItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' },
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function getLanguageName(localeId) {
            for (var i = 0; i < angularLangNames.length; i++) {
                if (angularLangNames[i].v === localeId)
                    return angularLangNames[i].n;
            }

            return localeId;
        }
    }
})();