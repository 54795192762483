(function () {

    angular
        .module("smartertools")
        .filter('reactPluralize', reactPluralizeFilter);

    function reactPluralizeFilter(i18n) {
        return function (translationKey, props) {
            return i18n.reactPluralize(translationKey, props);
        };
    }

    angular
        .module("smartertools")
        .filter('reactPluralizeWithCommas', reactPluralizeWithCommasFilter);

    function reactPluralizeWithCommasFilter(i18n, $filter) {
        return function (translationKey, props) {
            props.count = $filter('number')(props.count || 0);
            return i18n.reactPluralize(translationKey, props);
        };
    }
})();