(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("usersStatusesControllers", usersStatusesControllers);

    function usersStatusesControllers($state, $rootScope, $scope, $http, $compile, $mdDialog, $translate, $filter, NgTableParams, gridCheckboxes,
        successHandling, userDataService, errorHandling, coreDataFileStorage, i18n) {

        var vm = this;
        vm.domain = $state.params.id || userDataService.user.domain;
        vm.searchText = "";
        vm.searchParams = {
            domain: vm.domain,
            skip: 0,
            take: 0,
            search: null,
            sortField: null,
            sortDescending: false
        };
        vm.searchItems = searchItems;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.refresh = refresh;
        vm.reindexItems = reindexItems;
        vm.expirePassword = expirePassword;
        vm.exportToCsv = exportToCsv;

        activate();

        function activate() {
            vm.tableParams = new NgTableParams(
                {
                    sorting: { emailAddress: 'asc' },
                    count: 25
                }, {
                getData: queryData,
                counts: $rootScope.commonTableCounts
            });
            vm.checkboxes = gridCheckboxes.init('emailAddress');
            vm.checkboxes.table = vm.tableParams;
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

        function refresh() {
            vm.checkboxes.reset();
            vm.tableParams
                .reload()
                .then(function () { }, function () { })
                .finally(function () { successHandling.report('ACTION_SUCCEEDED'); });
        }

        function queryData(params) {
            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();
            vm.searchParams.sortField = null;

            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
                break;
            }

            $rootScope.spinner.show();
            return $http
                .post('~/api/v1/settings/domain/users-statuses', JSON.stringify(vm.searchParams))
                .then(function (result) {
                    vm.searchResults = result.data.usersStatuses;
                    vm.searchResultCount = result.data.totalCount;
                    params.total(vm.searchResultCount);
                    return vm.searchResults;
                }, function (result) { })
                .catch(function (e) { })
                .finally(function () {
                    $rootScope.spinner.hide();
                });
        }

        function contextMenuGridItem(item, ev) {
            var showMenu = true;
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            var items = vm.checkboxes.getCheckedItems();

            if ((items.length > 1 && !_.some(items, function (val) { return val = item.emailAddress })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [item.emailAddress];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'reindexItems', click: reindexItems, translateKey: 'REINDEX' },
                { key: 'expirePassword', click: expirePassword, translateKey: 'EXPIRE_PASSWORD' },
                { key: 'exportToCsv', click: exportToCsv, translateKey: 'EXPORT_TO_CSV' },
            ];

            var selected = {};
            for (var i = 0; i < items.length; ++i) {
                selected[items[i]] = true;
            }

            if (showMenu) {
                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function reindexItems() {
            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($translate.instant("CONFIRMATIONS_REINDEX"))
                .ok($translate.instant("REINDEX"))
                .noWarn(true);
            $mdDialog
                .show(confirm)
                .then(startReindex, function () { });

            function startReindex(modalSuccess) {
                var users = [];
                angular.forEach(vm.checkboxes.getCheckedItems(), function (card, key) {
                    users.push(card);
                });

                $http
                    .post("~/api/v1/settings/domain/reindex-users", JSON.stringify({ input: users }))
                    .then(function (sucess) {
                        successHandling.report;
                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                    }, errorHandling.report);
            }
        }

        function expirePassword() {
			var rows = vm.checkboxes.getCheckedItems();

            var confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent(i18n.reactPluralize("EXPIRE_PASSWORD_MESSAGE", { count: rows.length }))
                .ok($translate.instant("EXPIRE_PASSWORD"))
                .noWarn(true);
            $mdDialog
                .show(confirm)
                .then(doExpirePassword, function () { });

            function doExpirePassword() {
				var params = { emailAddresses: rows };
				$rootScope.spinner.show();

                $http
                    .post("~/api/v1/settings/expire-passwords", JSON.stringify(params))
                    .then(function (success) {
						if (!success.data.success) {
							errorHandling.report(success.data.message);
                            return;
						}

                        if (success.data.activeDirectoryCount > 0)
							errorHandling.warn("EXPIRE_PASSWORD_ACTIVEDIRECTORY");
						else
							successHandling.report();

                        vm.checkboxes.reset();
                        vm.tableParams.reload();
                    }, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function exportToCsv() {
            $rootScope.spinner.show();
            coreDataFileStorage
                .downloadFile("~/api/v1/settings/domain/export-users-statuses", $filter("translate")("USERS_STATUSES") + ".csv", {})
                .then(function () { }, errorHandling.report)
                .finally($rootScope.spinner.hide);
        }
    }
})();