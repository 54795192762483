(function () {

    angular
        .module("smartertools")
        .filter('timespanTimer', timespanTimer);

    function timespanTimer($filter) {
        return function (obj) {
            
            let s = "";
            if (!obj)
                return "00:00:00";
            
            if (obj.days){
                s += $filter('reactPluralize')("X_DAYS", {count:obj.days}) + " ";
            }
            
            s+= String(obj.hours || 0).padStart(2, '0') + ":";
            s+= String(obj.minutes || 0).padStart(2, '0') + ":";
            s+= String(obj.seconds || 0).padStart(2, '0');
            
            return s;
        }
    }

})();

