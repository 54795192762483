(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsGeneralSignaturesController", domainSettingsGeneralSignaturesController);

	function domainSettingsGeneralSignaturesController($rootScope, $scope, $http, $timeout, $filter, $compile, $q, $sce,
		$log, $mdDialog, $mdMedia, $state, coreData, coreDataSettings, coreDataDomainSettings, errorHandling, i18n) {

		var vm = this;

		$scope.signatureMappings = [];
		$scope.availableMappings = [];
		$scope.edition = 1;
		var domainSettings = {};

		// Functions
		vm.getSignatureFromGuid = getSignatureFromGuid;
		vm.newSignature = newSignature;
		vm.editSignature = editSignature;
		vm.editMapping = editMapping;
		vm.deleteItem = deleteItem;

		activate();

		//////////////////////////////

		function activate() {
			$rootScope.spinner.show();

			var promises = [
				$http.get("~/api/v1/settings/domain/domain"),
				$http.get("~/api/v1/settings/domain/signature-mappings"),
				$http.get("~/api/v1/settings/domain/signatures"),
				coreDataSettings.settingsData.signatureVariables
			];

			$q.all(promises)
				.then(onSignatureSettingsLoaded, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSignatureSettingsLoaded(result) {
				domainSettings = angular.copy(result[0].data.domainSettings);
				$scope.signatureMappings = result[1].data.maps;
				$scope.signatures = angular.copy(result[2].data.signatureConfigs || []);
				$scope.variables = result[3];

				$scope.availableMappings = [
					{ name: "NONE", signatureGuid: "", signatureMapOption: 0 },
					{ name: "USE_PRIMARY_SIGNATURE", signatureGuid: "", signatureMapOption: 1 }
				];

				angular.forEach($scope.signatures, function (value, key) {
					$scope.availableMappings.push({ name: value.name, signatureGuid: value.guid, signatureMapOption: 2 });
				});

				initSignatures();
			}
		}

		function initSignatures() {
			var varKeys = Object.keys($scope.variables);
			for (var i = 0; i < varKeys.length; ++i) {
				$scope.variables[varKeys[i]] = $filter('translate')($scope.variables[varKeys[i]]);
			}

			for (var i = 0; i < $scope.signatureMappings.length; i++) {
				$scope.signatureMappings[i].signature = getSignatureFromGuid($scope.signatureMappings[i].signatureGuid, $scope.signatureMappings[i].mapOption)
			}
		}

		function getSignatureFromGuid(guid, mapOption) {
			if (mapOption === 2) {
				for (var i = 0; i < $scope.availableMappings.length; ++i) {
					var value = $scope.availableMappings[i];
					if (value.signatureGuid === guid && value.signatureMapOption === mapOption) {
						return value;
					}
				}
			} else {
				for (var i = 0; i < $scope.availableMappings.length; ++i) {
					var value = $scope.availableMappings[i];
					if (value.signatureMapOption === mapOption) {
						return value;
					}
				}
			}
		}

		function newSignature(ev) {
			$mdDialog.show({
				locals: {
					name: "",
					body: "",
					newItem: true,
					variables: $scope.variables,
					sigId: 0,
					domainOverride: ($rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain)
				},
				controller: "domainEditSignatureController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/email-customization/signature.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (modalSuccess.deleteItem) {
					} else {
						var name = modalSuccess.name;
						var body = modalSuccess.body;
						if (name !== '') {
							var params = { signatureConfig: { id: "", name: name, text: body, isDefault: false } };
							$http.post('~/api/v1/settings/domain/signature-put', params)
								.then(function (success) {
									var id = success.data.signatureID;
									var guid = success.data.signatureGuid;
									var newSignature = { id: id, guid: guid, name: name, text: body, isDefault: false };

									if (!$scope.signatures) $scope.signature = [];
									$scope.signatures.push(newSignature);
									$scope.signatures.sort(function(a, b) { return a.name.localeCompare(b.name); });
									$scope.availableMappings.push({ name: name, signatureGuid: guid, signatureMapOption: 2 });
								}, errorHandling.report);
						}
					}
				}, function () { });
		}

		function editSignature(ev, signature) {
			$mdDialog.show({
				locals: {
					name: signature.name,
					body: signature.text,
					newItem: false,
					variables: $scope.variables,
					sigId: signature.id,
					domainOverride: ($rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain) 
				},
				controller: "domainEditSignatureController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/email-customization/signature.dlg.html",
				targetEvent: ev
			})
			.then(function (modalSuccess) {
				if (modalSuccess.deleteItem) {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS_one'))
						.targetEvent(event);
					$mdDialog.show(confirm)
						.then(function () { vm.deleteItem(signature); }, function () {});
				} else {
					var name = modalSuccess.name;
					var body = modalSuccess.body;
					if (name !== signature.name || body !== signature.text) {
						var updatedSignature = { id: signature.id, guid: signature.guid, name: name, text: body, isDefault: signature.isDefault }
						var params = { signatureConfig: updatedSignature };
						$http.post('~/api/v1/settings/domain/signature', params)
							.then(function (success) {
								for (var i = 0; i < $scope.signatures.length; ++i) {
									if ($scope.signatures[i].guid === updatedSignature.guid) {
										$scope.signatures[i] = updatedSignature;
										break;
									}
								}
								for (var i = 0; i < $scope.availableMappings.length; ++i) {
									if ($scope.availableMappings[i].signatureGuid === updatedSignature.guid) {
										$scope.availableMappings[i] = { name: updatedSignature.name, signatureGuid: updatedSignature.guid, signatureMapOption: 2 };
										break;
									}
								}
								angular.forEach($scope.signatureMappings, function (value, key) {
									if (value.signature.signatureGuid === updatedSignature.guid) {
										value.signature.name = updatedSignature.name;
									}
								});
							}, errorHandling.report);
					}
				}
			}, function () { });
		}

		function editMapping(ev, index, form) {
			$mdDialog.show({
				locals: {
					mapping: $.extend(true, {}, $scope.signatureMappings[index]),
					availableMappings: $scope.availableMappings
				},
				controller: "domainEditMappingController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/domain-settings/email-customization/mapping.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if ($scope.signatureMappings[index].signatureGuid !== modalSuccess.signature.signatureGuid
						|| $scope.signatureMappings[index].allowUsersToOverride !== modalSuccess.allowUsersToOverride
						|| $scope.signatureMappings[index].mapOption !== modalSuccess.mapOption)
						form.$setDirty();
					$scope.signatureMappings[index] = modalSuccess;
				}, function () { });
		}

		function deleteItem(signature) {
			$http
				.post('~/api/v1/settings/domain/signature-delete/' + signature.id)
				.then(function (success) {
					for (var i = 0; i < $scope.signatures.length; ++i) {
						if ($scope.signatures[i].guid === signature.guid) {
							$scope.signatures.splice(i, 1);
							break;
						}
					}
					for (var i = 0; i < $scope.availableMappings.length; ++i) {
						if ($scope.availableMappings[i].signatureGuid === signature.guid) {
							$scope.availableMappings.splice(i, 1);
							break;
						}
					}
					angular.forEach($scope.signatureMappings, function (value, key) {
						if (value.signature.signatureGuid === signature.guid) {
							if (value.type === 2) {
								value.name = "NONE";
								value.signatureGuid = "";
								value.mapOption = 0;
								value.signature = vm.getSignatureFromGuid(value.signatureGuid, value.mapOption);
							} else {
								value.name = "USE_PRIMARY_SIGNATURE";
								value.signatureGuid = "";
								value.mapOption = 1;
								value.signature = vm.getSignatureFromGuid(value.signatureGuid, value.mapOption);
							}
						}
					});
					saveMappingsCard();
				}, errorHandling.report);
		}

		function saveMappingsCard() {
			$http.post('~/api/v1/settings/domain/signature-mappings', { signatureMaps: $scope.signatureMappings })
				.then(function() { }, errorHandling.report);

			
		}
	}

})();
