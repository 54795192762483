(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityAntiVirusPageController", sysAdminSecurityAntiVirusPageController);

	function sysAdminSecurityAntiVirusPageController($rootScope, $scope, $http, $translate, $q, $mdDialog, errorHandling, claimsService, i18n, $filter) {
		var vm = this;

		$scope.cardService = $scope.mainSetCtrl.cardService;

		vm.canUpdateClam = false;

		$scope.defaultActionOptions = [
			{ value: 0, translation: 'NO_ACTION' },
			{ value: 1, translation: 'DELETE_MESSAGE' },
			{ value: 2, translation: 'QUARANTINE_MESSAGE' }
		];

		vm.virusCheckOptions = [
			{ value: 0, translation: 'BOTH' },
			{ value: 1, translation: 'INCOMING' },
			{ value: 2, translation: 'OUTGOING' }
		];

		// Functions
		vm.save = save;
		vm.doCancel = doCancel;
		vm.updateClamAV = updateClamAV;
        vm.clamEnabled = clamEnabled;

		activate();

		////////////////////////////////////

		function activate() {
			refresh();
		}

		function refresh() {
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/antivirus/settings").then(onVirusSettingsLoaded, function () {}),
				$http.get('~/api/v1/settings/sysadmin/cyren-licensed').then(onCheckCyrenLicensedSuccess, onCheckCyrenLicensedError)
			];

			$rootScope.spinner.show();
			$q
				.all(promises)
				.finally($rootScope.spinner.hide);

			function onVirusSettingsLoaded(result) {
				vm.settings = result.data.settings;
				vm.canUpdateClam = vm.settings.clam &&
					(vm.settings.clam.scanMessages || vm.settings.clam.scanUploadedFiles || vm.settings.clam.scanMessagesWithoutAttachments) &&
					!vm.settings.clam.isRemoteServer;

				if (window.stOs === 'W') {
					if (moment.utc(vm.settings.clam.virusDefinitionsUpdated, "YYYY-MM-DD HH:mm").format("YYYY") > 1900) {
						const updateDate = new Date(vm.settings.clam.virusDefinitionsUpdated);
						$scope.virusDefinitions = $translate.instant("ANTI_VIRUS_LAST_UPDATED", {
							date: $filter("date")(updateDate, "shortDate"), 
							time: $filter("date")(updateDate, "shortTime")
						});
					}
					else
						$scope.virusDefinitions = $translate.instant("ANTI_VIRUS_UPDATING");
				}
			}

			function onCheckCyrenLicensedSuccess(result) {
				$scope.cyrenLicensed = result.data.isCyrenAntivirusLicensed;
			}

			function onCheckCyrenLicensedError() {
				$scope.cyrenLicensed = false;
			}
		}

		function doCancel(ev, form) {
			if (!form.$dirty) return;
			$mdDialog
				.show($mdDialog.confirmRevertChanges().targetEvent(ev))
				.then(function () { refresh(); form.$setPristine(); }, function () { });
		}

		function save(ev, form) {
			if (form.$pending) return;

			$rootScope.spinner.show();
			var params = JSON.stringify({
                commandLine: vm.settings.commandLine,
				commandLineScanMessages: vm.settings.commandLineScanMessages,
				commandLineScanFiles: vm.settings.commandLineScanFiles,
				commandLineScanMessagesWithoutAttachments: vm.settings.commandLineScanMessagesWithoutAttachments,
                clam: vm.settings.clam,
				cyren: vm.settings.cyren,
				defender: vm.settings.defender
			});

			$http.post("~/api/v1/settings/sysadmin/antivirus/settings", params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				form.$setPristine();
				vm.canUpdateClam = vm.settings.clam &&
					(vm.settings.clam.scanMessages || vm.settings.clam.scanUploadedFiles || vm.settings.clam.scanMessagesWithoutAttachments) &&
					!vm.settings.clam.isRemoteServer;
			}
		}

		function clamEnabled(justSpool) {
			if (!vm.settings)
				return false;
			else if (!vm.settings.clam)
				return false;

			if (justSpool)
                return vm.settings.clam.scanMessages;
            else
                return vm.settings.clam.scanMessages ||
                    vm.settings.clam.scanUploadedFiles;
        }

        function updateClamAV() {
			$http.post("~/api/v1/settings/sysadmin/antivirus/update-clam-definitions")
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				if (window.stOs === 'W') {
					$scope.virusDefinitions = $translate.instant("ANTI_VIRUS_UPDATING");
				}
			}
        }
	}

})();
