(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userSettingsMainController", userSettingsMainController);

    function userSettingsMainController($rootScope, $scope, $http, $filter, $timeout, $stateParams, $state, $q, coreLicensing,
                                        coreData, coreDataSettings, coreDataDomainSettings, errorHandling, treeState, claimsService) {
        var vm = this;
        vm.isDomainAdmin = false;
        vm.isInitialized = false;
        vm.searchText = null;
        vm.canOverrideAutoClean = null;
        vm.enableActiveSyncAccountManagement = false;
        vm.showMessageArchiveSearch = false;
        vm.edition = 1;
        vm.isDomain = $stateParams.type === 'domain';
        vm.isEligibleEmClientLicenses = false;
        vm.isAnAdministrator = claimsService.isSysAdmin() || claimsService.isDomainAdmin();

        vm.userShortSections = [
            {title: 'ACCOUNT', sref: 'index.settings.user-account-settings'},
            {title: 'ACCOUNT_PROFILE', sref: 'index.settings.user-account-profile'},
        ];
        vm.userSections = [
            {title: 'AUTO_RESPONDER', sref: 'index.settings.user-auto-responder'},
            {title: 'CALENDAR', sref: 'index.settings.user-calendar-settings'},
            {title: 'CONNECTIVITY', sref: 'index.settings.user-external-accounts'},
            {title: 'CONTENT_FILTERING', sref: 'index.settings.user-content-filters'},
            {title: 'EVENTS', sref: 'index.settings.user-events'},

            {title: 'SIGNATURES', sref: 'index.settings.signatures'},
            {title: 'SPAM_FILTERING', sref: 'index.settings.user-spam-filters'},
        ];
        if (coreLicensing.license && (coreLicensing.edition == coreLicensing.EDITION_ENTERPRISE || coreLicensing.edition == coreLicensing.EDITION_FREE))
            vm.userSections.push({title: 'SCHEDULING_SETTINGS', sref: 'index.settings.user-scheduling-settings'})


        for (var i = 0; i < vm.userSections.length; i++) {
            var item = vm.userSections[i];
            item.translatedTitle = $filter('translate')(item.title);
        }
        vm.userSections = _.sortBy(vm.userSections, 'translatedTitle');

        vm.domainSections = [];
        $scope.treeExpanded = treeState.isExpanded;
        $scope.$on("treeState:stateChange", function (event, data) {
            $scope.treeExpanded = data.expanded;
            $timeout(function () {
                $(window).trigger('resize');
            }, 300);
        });

        $scope.settingCore = undefined;

        $scope.$on('signalR.mailHub.client.listModeratorChanged', function (event, data) {
            if (data.moderator) {
                var count = $.grep(vm.userSections, function (section) {
                    return section.title === 'MAILING_LISTS';
                }).length;
                if (count < 1) {
                    vm.userSections = vm.userSections.concat([
                        {title: 'MAILING_LISTS', sref: 'index.settings.mailing-lists'}
                    ]);
                }
            } else {
                $http.get('~/api/v1/settings/domain/mailing-lists/list')
                    .then(onLoadComplete, onLostPermissions);

                function onLoadComplete() {
                    if ($state.current.name.indexOf("index.settings.mailing-lists.specific") > -1) {
                        if ($stateParams.id == data.listId) {
                            $state.go("index.settings.mailing-lists");
                            errorHandling.report("MAILING_LISTS_LOST_PERMISSIONS");
                        }
                    }
                }

                function onLostPermissions() {
                    vm.userSections = $.grep(vm.userSections, function (section) {
                        return section.title !== 'MAILING_LISTS';
                    });
                    if ($state.current.name.indexOf("index.settings.mailing-lists") > -1) {
                        $state.go("index.settings.user-account-settings");
                    }
                    errorHandling.report("MAILING_LISTS_LOST_PERMISSIONS");
                }
            }
            $scope.$applyAsync();
        });

        // Functions
        vm.searchUpdate = searchUpdate;
        vm.isInSearch = isInSearch;
        vm.shouldShow = shouldShow;

        function checkEmClientEligibility() {
            try {
                switch (true) {
                    case coreLicensing.edition >= 2:
                        return false;
                    case !coreLicensing.license:
                        return false;
                    case coreLicensing.license.isTrialLicense:
                        return false;
                    case !vm.domainSettings.emClientEnabled:
                        return false;
                    case !vm.domainSettings.emClientOffersAvailable:
                        return false;
                    case !vm.domainSettings.isTopLevelDomain:
                        return false;
                    default:
                        return true;
                }
            } catch (e) {
                return false;
            }
        }
        
        activate();

        //////////////////

        async function activate() {
            $rootScope.spinner.show();

            var promises =
                vm.isDomain
                    ? [coreData.init(), $http.get("~/api/v1/settings/domain/domain")]
                    : [coreData.init()];
            $q.all(promises).then(onCoreDataLoaded, onCoreDataFailed);

            function onCoreDataLoaded(success) {
                vm.domainSettings = success && success.length >= 2 ? success[1].data.domainSettings : null;
                vm.isDomainAdmin = coreData.user.isDomainAdmin;
                vm.showDomainAliasMenu = vm.domainSettings && vm.domainSettings.showDomainAliasMenu;
                vm.customLoginDisplay = vm.domainSettings && vm.domainSettings.customLoginDisplay;
                vm.enableActiveSyncAccountManagement = vm.domainSettings && vm.domainSettings.enableActiveSyncAccountManagement;
                vm.showMessageArchiveSearch = coreDataDomainSettings.domainPermissions.showMessageArchiveSearch;
                vm.canOverrideAutoClean = coreDataSettings.userPermissions.autoCleanAllowUserOverride;
                vm.isListModerator = coreDataSettings.userPermissions.isListModerator;
                vm.showMailingListMenu = coreDataSettings.userPermissions.showListMenu;
                vm.showAutoresponderMenu = coreDataSettings.userPermissions.showAutoresponderMenu;
                $scope.settingCore = coreDataSettings;
                if (vm.isDomainAdmin) {
                    setupDomainSections();
                }
                if (vm.showMailingListMenu && (vm.isListModerator || vm.isDomainAdmin)) {
                    vm.userSections = vm.userSections.concat([
                        {title: 'MAILING_LISTS', sref: 'index.settings.mailing-lists'}
                    ]);
                }

                if (coreDataSettings.userDomainSettings.enableSharing) {
                    var indexToInsert = vm.userSections.findIndex(item => item.title == 'SIGNATURES');//{ title: 'SIGNATURES', sref: 'index.settings.signatures' });
                    vm.userSections.splice(indexToInsert, 0, {title: 'SHARING', sref: 'index.settings.user-sharing'});
                }

                if (!vm.showAutoresponderMenu) {
                    vm.userSections = $.grep(vm.userSections, function (s) {
                        return s.title != 'AUTO_RESPONDER';
                    });
                }

                for (var i = 0; i < vm.userSections.length; i++) {
                    var item = vm.userSections[i];
                    item.translatedTitle = $filter('translate')(item.title);
                }
                vm.userSections = _.sortBy(vm.userSections, 'translatedTitle');
                recalculateLicense();
                coreLicensing.watchForChanges($scope, recalculateLicense);

                checkEmClientEligibility();
                vm.isInitialized = true;
                $rootScope.spinner.hide();
            }

            function onCoreDataFailed(failure) {
                errorHandling.report(failure);
                $rootScope.spinner.hide();
            }
        }

        function recalculateLicense() {
            vm.edition = coreLicensing.edition;
            vm.isEligibleEmClientLicenses = checkEmClientEligibility();
            if (vm.isInitialized)
                setupDomainSections();
        }

        function shouldShow(section) {
            return section != null && (!section.showif || section.showif());
        }

        function searchUpdate() {
        }

        function isInSearch(page) {
            if (!vm.searchText)
                return true;
            return page.indexOf(vm.searchText) !== -1;
        }

        function setupDomainSections() {
            vm.domainShortSections = [
                {title: 'ACCOUNTS', sref: 'index.settings.domain-users'},
                {title: 'USER_CONNECTIONS', sref: 'index.settings.domain-users-connections'},
                {title: 'USER_STATUSES', sref: 'index.settings.domain-users-statuses'},
                {title: 'GENERAL', sref: 'index.settings.domain-general-settings'},
            ];
            if (vm.isEligibleEmClientLicenses) {
                vm.domainShortSections = vm.domainShortSections.concat([
                    {title: 'EMCLIENT', sref: 'index.settings.domain-emclient-licenses'}
                ]);
            }

            vm.domainSections = [
                {title: 'EVENTS', sref: 'index.settings.domain-events'},
                {title: 'SIGNATURES', sref: 'index.settings.domain-email-customization'},
                {title: 'SPAM_FILTERING', sref: 'index.settings.domain-spam-filtering'},
                {title: 'CONTENT_FILTERING', sref: 'index.settings.domain-content-filtering'},
                {title: 'USER_DEFAULTS', sref: 'index.settings.user-defaults'},
                {title: 'SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS', sref: 'index.settings.domain-password-requirements'},
            ];

            if (coreDataSettings.userDomainSettings.enableSharing) {
                var indexToInsert = vm.domainSections.findIndex(item => item.title == 'USER_DEFAULTS');//{ title: 'SIGNATURES', sref: 'index.settings.signatures' });
                vm.domainSections.splice(indexToInsert, 0, {title: 'SHARING', sref: 'index.settings.domain-sharing'});
            }

            if (vm.showMessageArchiveSearch) {
                vm.domainSections = vm.domainSections.concat([
                    {title: 'MESSAGE_ARCHIVE_SEARCH', sref: 'index.settings.domain-message-archive'}
                ]);
            }

            if (vm.domainSettings && vm.domainSettings.enableDomainHistory && vm.domainSettings.marketPlace.smarterMailChatEnabled)
                vm.domainSections.push({title: 'CHAT_SEARCH', sref: 'index.settings.domain-chat-search'});

            for (var i = 0; i < vm.domainSections.length; i++) {
                var item = vm.domainSections[i];
                item.translatedTitle = $filter('translate')(item.title);
            }
            vm.domainSections = _.sortBy(vm.domainSections, 'translatedTitle');
        }
    }
})();