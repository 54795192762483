(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedMailingListSubscribersController", sharedMailingListSubscribersController);

	function sharedMailingListSubscribersController($state, $rootScope, $scope, $http, $filter, $mdDialog, $compile, $stateParams, $timeout,
		NgTableParams, gridCheckboxes, errorHandling, successHandling, coreDataFileStorage, i18n) {
		var vm = this;
		vm.isDomain = $stateParams.type === "domain";
		vm.mailingListId = $state.params.mailingListId;
		vm.tableParams = new NgTableParams(
			{
				sorting: { emailaddress: "asc" },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("emailAddress");
		vm.checkboxes.table = vm.tableParams;
		vm.cols = [];
		vm.doubleOptIn = false;

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.deleteItems = deleteItems;
		vm.deleteAll = deleteAll;
		vm.searchItems = function () { vm.tableParams.reload(); }
		vm.importEmails = importEmails;
		vm.exportEmails = exportEmails;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.resendVerification = resendVerification;

		activate();

		//////////////////////////

		function activate() {
			$http.get('~/api/v1/settings/domain/mailing-lists/' + vm.mailingListId + '/settings')
				.then(function(success) {
					vm.doubleOptIn = success.data.item.doubleOptIn;
				}, function () { });
		}

		function signalCountChanged() {
			$rootScope.$broadcast("mailing-list:counts-changed");
		}

		function queryData(params) {
			var skip = (params.page() - 1) * params.count();
			var take = params.count();
			var endIndex = skip + take;
			var sortField = "emailAddress";
			var sortDescending = false;

			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortField = k;
				sortDescending = params.sorting()[k] === "desc";
				break;
			}

			// TODO:: Pass in sortField and sortDescending to api, api also needs to accomodate this
			var postParams = {
				"skip": (params.page() - 1) * params.count(),
				"take": params.count(),
				"search": vm.searchText,
				"subscriberType": vm.subscriberType,
				"sortField": sortField,
				"sortDescending": sortDescending
			}

			return $http
				.post("~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/subscriber-search", JSON.stringify(postParams))
				.then(function (result) {
					params.total(result.data.total);
					return result.data.items;
				}, function () { })
				.finally(function () {
					// Give ngTable time to populate vm.cols
					$timeout(function () {
						if (vm.cols.length >= 5)
							vm.cols[4].show = function (){ return vm.doubleOptIn; }
					});
				});
		}

		function newItem(ev) {
			$mdDialog.show({
				locals: {
					id: undefined,
					email: undefined,
					newItem: { hideMailLists: true, mailingListID: vm.mailingListId }
				},
				controller: "sharedAddSubscriberController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/subscriber-add.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					var uri = (vm.subscriberType === "Subscriber")
						? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/subscriber-add"
						: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/digest-subscriber-add";
					$http
						.post(uri, JSON.stringify(modalSuccess.emailAddresses))
						.then(function () {
                            fireDataChanged();
                            successHandling.report();
                        }, errorHandling.report);
				}, function () {});
		}

		function deleteAll(event) {
			var confirm = $mdDialog
				.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS_ALL"))
				.targetEvent(event);
			$mdDialog
				.show(confirm)
				.then(doDelete, function () {});

			function doDelete() {
				var uri = (vm.subscriberType === "Subscriber")
					? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/subscriber-remove-all"
					: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/digest-subscriber-remove-all";
				$http
					.post(uri)
					.then(fireDataChanged, errorHandling.report);
			}
		}

		function deleteItems(event) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards || !cards.length)
				return;
			if (!angular.isArray(cards))
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: cards.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, function () {});

			function doDelete() {
				var uri = (vm.subscriberType === "Subscriber")
					? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/subscriber-remove"
					: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/digest-subscriber-remove";
				$http.post(uri, JSON.stringify(cards)).then(fireDataChanged, errorHandling.report);
			}
		}

		function fireDataChanged() {
			vm.checkboxes.reset();
			signalCountChanged();
			vm.tableParams.reload();
		}

		function editItem(selectedCard, ev) {
			$mdDialog
				.show({
					locals: {
						id: selectedCard.id,
						email: selectedCard.emailAddress,
						mailingListId: vm.mailingListId
					},
					controller: "sharedEditSubscriberController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/mailing-lists/modals/subscriber.dlg.html",
					targetEvent: ev
				})
				.then(commitEdit, refreshTable);

			function commitEdit(modalSuccess) {
				var params = {
					emailAddress: modalSuccess.subscriber.emailAddress,
					fieldValues: null,
					subscribedLists: null,
					subscribedDigests: null
				};

				//if (modalSuccess.subscriber.fieldValues) {
				//	params.fieldValues = {};
				//	angular.forEach(modalSuccess.subscriber.fieldValues, function (value,key) {
				//		params.fieldValues[key] = value;
				//	});
				//}

				params.fieldValues = modalSuccess.subscriber.fieldValues;
				params.subscribedLists = modalSuccess.subscriber.subscribedLists;
				params.subscribedDigests = modalSuccess.subscriber.subscribedDigests;

				$http
					.post("~/api/v1/settings/domain/mailing-lists/subscribers/" + selectedCard.emailAddress + "/edit", JSON.stringify(params))
					.then(
                        function () {
						    fireDataChanged(); 
                            successHandling.report();
						},
                        errorHandling.report);
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.emailAddress })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.emailAddress];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "resendOptIn", click: resendVerification, translateKey: "RESEND_DOUBLE_OPT_IN_VERIFICATION" },
				{ divider: true },
				{ key: "deleteItems", click: deleteItems, translateKey: "DELETE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function resendVerification(event) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards || !cards.length)
				return;
			if (!angular.isArray(cards))
				return;

			$http
				.post("~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/resend-opt-in",
					JSON.stringify(cards))
				.then(function() {
						fireDataChanged();
						successHandling.report();
					},
					errorHandling.report);
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}


		function importEmails(event) {
			$mdDialog
				.show({
					locals: {
						options: { subscriberType: vm.subscriberType, filterListID: vm.mailingListId }
					},
					controller: "sharedUploadSubscribersController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/mailing-lists/modals/upload-subscribers.dlg.html",
					targetEvent: event
				})
				.then(function() {
					fireDataChanged();
					successHandling.report();
				}, function() {});
		}

		function exportEmails(event) {
			var uri = (vm.subscriberType === "Subscriber")
				? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/subscriber-export"
				: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/digest-subscriber-export";

			var fileName = (vm.subscriberType === "Subscriber")
				? $filter("translate")("SUBSCRIBERS") + ".csv"
				: $filter("translate")("DIGEST_SUBSCRIBERS") + ".csv";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(uri, fileName, {})
				.then(function() {}, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}
	}

})();




