(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("eventActionEditDialogController", eventActionEditDialogController);

	function eventActionEditDialogController($scope, $mdDialog, $filter, $timeout, $translate, $http, toaster, coreData,
		eventGuid, eventId, newAction, availableEvents, availableVariables, action, defaultEmail, level, coreDataSettings, coreDataDomainSettings, i18n) {
		var vm = this;
		
		$scope.level = level;
		$scope.action = action;
		$scope.eventId = eventId;
		$scope.eventGuid = eventGuid;
		$scope.newAction = newAction;
		$scope.availableEvents = availableEvents;
		$scope.isValid = true;
		$scope.availableVariables = availableVariables[eventId];
		$scope.variables = {};
		angular.forEach($scope.availableVariables, function (variable) {
			$scope.variables[variable] = getVariableName(variable);
		});
		$scope.customVariables = [{ key: "var_dropdown", options: $scope.variables, icon: "cog", title: $filter("translate")("SIGNATURES_CUSTOM_VARIABLES"), callback: variableCallback }];
		$scope.repeatTime = 0;
		$scope.defaultEmail = defaultEmail;
		$scope.possibleActions = $scope.availableEvents[eventId].possibleActions;
		$scope.possibleActions = $.grep($scope.possibleActions, function (action) {
			if ($scope.level == "domain")
				return action.key === "CommandLineAction";
			else // user
				return action.key === "CommandLineAction" || action.key === "AlertUserAction";
		}, true);
		$scope.currentActionKey = !newAction ? $scope.action.key : "";
		$scope.addAddress = "";
		$scope.recipient = "";
		$scope.fromAddress = "";
		vm.toAddress = "";
		$scope.subject = "";
		$scope.body = "";
		$scope.recipientOptions = [];
		$scope.recipientType = "None";
		$scope.repeatOptions = [
			 { value: 0, translation: $filter("translate")("NONE") },
			 { value: 1, translation: i18n.reactPluralize("EVENT_ACTIONS_MINUTES", { count: 1 }) },
			 { value: 2, translation: i18n.reactPluralize("EVENT_ACTIONS_MINUTES", { count: 2 }) },
			 { value: 5, translation: i18n.reactPluralize("EVENT_ACTIONS_MINUTES", { count: 5 }) },
			 { value: 15, translation: i18n.reactPluralize("EVENT_ACTIONS_MINUTES", { count: 15 }) },
			 { value: 30, translation: i18n.reactPluralize("EVENT_ACTIONS_MINUTES", { count: 30 }) },
			 { value: 60, translation: i18n.reactPluralize("EVENT_ACTIONS_HOURS", {count: 1}) },
			 { value: 120, translation: i18n.reactPluralize("EVENT_ACTIONS_HOURS", { count: 120 / 60 }) },
			 { value: 300, translation: i18n.reactPluralize("EVENT_ACTIONS_HOURS", { count: 300 / 60 }) },
			 { value: 720, translation: i18n.reactPluralize("EVENT_ACTIONS_HOURS", { count: 720 / 60 }) },
			 { value: 1440, translation: i18n.reactPluralize("EVENT_ACTIONS_HOURS", { count: 1440 / 60 }) }
		];

		$scope.alertEditorScope = {};
		$scope.emailEditorScope = {};

		// Functions
		$scope.getSetAction = getSetAction;
		$scope.parseRepeatTime = parseRepeatTime;
		$scope.getVariableName = getVariableName;
		$scope.save = save;
		$scope.delete = deleteThis;
		$scope.cancel = cancel;
		$scope.isDomainAdmin = isDomainAdmin;

		activate();

		///////////////////////

		function activate() {
			loadActionVars(false);
			$scope.parseRepeatTime();
		}

		function loadActionVars(modifyingAction) {
			$scope.subject = $filter("translate")("EVENT_ACTIONS_DEFAULTS_" + $scope.eventId + "_SUBJECT");
			$scope.body = $filter("translate")("EVENT_ACTIONS_DEFAULTS_" + $scope.eventId + "_BODY");
			$scope.recipientOptions = [];
			
			if ($scope.currentActionKey && $scope.currentActionKey !== "") {

				$scope.actionTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
				if ($scope.actionTemplate[0] && $scope.actionTemplate[0].supportedActionRecipients) {
					var supportedRecipientTypes = $scope.actionTemplate[0].supportedActionRecipients;
					var recipients = supportedRecipientTypes.replace(/\s+/g, '').split(',').reverse();
					recipients.forEach(r => {
						if (r === "SystemAdmins") return;
						if ($scope.level === "user" && r === "DomainAdmins") return;
						if ($scope.currentActionKey === "EmailAction" && $scope.level === "user" && r === "Users") return;

						let levelEnding = '';
						if (r.toUpperCase() === "DOMAINADMINS")
							levelEnding = $scope.level === 'domain' ? '_ALL' : "_SOME";
						if (r.toUpperCase() === "USERS")
							levelEnding = $scope.level === 'user' ? '_ME' : "_AFFECTED";
						
						$scope.recipientOptions.push({
							value: r,
							translation: $filter('translate')("EVENT_ACTIONS_RECIPIENT_TYPE_" + r.toUpperCase() + levelEnding)
						});
					});
				} 
			}
			if (!$scope.newAction) {
				angular.forEach($scope.action.inputs, function (input) {
					switch (input.descriptionResourceId) {
					case "@EventInput_RecipientType":
							$scope.recipientType = input.value;
							break;
						case "@EventInput_FromAddress":
								$scope.fromAddress = input.value;
							break;
						case "@EventInput_ToAddress":
								vm.toAddress = input.value;
							break;
						case "@EventInput_AddressToAdd":
							$scope.addAddress = input.value;
							break;
						case "@EventInput_Recipient":
								$scope.recipient = input.value;
							break;
						case "@EventInput_Subject":
							if (!modifyingAction)
								$scope.subject = input.value;
							break;
						case "@Body":
							if (!modifyingAction)
								$scope.body = input.value;
							break;
						case "@EventInput_BrowserNotify":
							$scope.browserNotify = input.value === "True";
							break;
					}
				});


			}
			if ($scope.recipientType === "None" && $scope.recipientOptions.length > 0) {
				$scope.recipientType = $scope.recipientOptions[0].value;
			} 
			if (modifyingAction) {
				if (!$scope.fromAddress)
					$scope.fromAddress = $scope.defaultEmail;
				if (!vm.toAddress)
					vm.toAddress = $scope.defaultEmail;
				if ($scope.recipientOptions.length > 0 && !$scope.recipientOptions.some(x => x.value === $scope.recipientType)) {
					$scope.recipientType = $scope.recipientOptions[0].value;
				}
			}
			$scope.showRecipientOptions = $scope.recipientOptions > 1 || $scope.recipientType === "DomainAdmins";
		}

		function getSetAction(value) {
			if (arguments.length) { // Set
				$scope.currentActionKey = value;

				if (!$scope.newAction) {
					$scope.newTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
					if ($scope.newTemplate[0]) {
						var oldId = $scope.action.id;
						$scope.action = $scope.newTemplate[0];
						$scope.action.id = oldId;
						$scope.fromAddress = $scope.defaultEmail;
						vm.toAddress = $scope.defaultEmail;
					}
				}
				loadActionVars(true);
			} else { // Get
				return $scope.currentActionKey;
			}
		}

		function parseRepeatTime() {
			var parsedRepeat = $scope.action.constraintFrequency.split(":");
			$scope.repeatTime = Number(parsedRepeat[0] > 0 ? parsedRepeat[0] * 60 : 0) + Number(parsedRepeat[1] > 0 ? parsedRepeat[1] : 0);
		};

		function getVariableName(variable) {
			// Regular expression variable causes an error in HTML, so need to do this conversion here
			return $filter("translate")("EVENT_CONDITIONS_" + variable.toUpperCase().replace(/#/g, ""));
		};

		function variableCallback(cmd, val, scope) {
			$scope.form.$setDirty(); $scope.$applyAsync();
			if (scope.insertHtml) {
				scope.insertHtml(val);
			}
		};

		function getConstraintTime() {
			var hours = Math.floor($scope.repeatTime / 60);
			var minutes = Math.floor($scope.repeatTime % 60);
			return (hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + ":00";
		}

		function save(userForm) {
			if (userForm.$invalid) {
				return;
			}

			let actionBody = $scope.body;
			switch ($scope.currentActionKey) {
				case "AlertAction":
					actionBody = $scope.alertEditorScope.getHtml();
					break;
				case "EmailAction":
					actionBody = $scope.emailEditorScope.getHtml();
					break;
			}

			$timeout(async function () {
				if ($scope.newAction) {
					$scope.newTemplate = $.grep($scope.possibleActions, function (action) { return action.key === $scope.currentActionKey; });
					if ($scope.newTemplate[0]) {
						// do a "deep copy" of the selected template
						$scope.action = JSON.parse(JSON.stringify($scope.newTemplate[0]));
					}
					else
						return;
					$scope.action.id = "";
				}
				$scope.action.defaultFrequencyInMinutes = $scope.repeatTime;
				$scope.action.constraintFrequency = getConstraintTime();
				angular.forEach($scope.action.inputs, function (input) {
					switch (input.descriptionResourceId) {
						case "@EventInput_Owner":
							input.value = $scope.defaultEmail;
							break;
						case "@EventInput_RecipientType":
							input.value = $scope.recipientType;
							break;
						case "@EventInput_FromAddress":
							input.value = $scope.fromAddress;
							break;
						case "@EventInput_ToAddress":
							input.value = vm.toAddress;
							break;
						case "@EventInput_AddressToAdd":
							input.value = $scope.addAddress;
							break;
						case "@EventInput_Recipient":
							input.value = $scope.recipient;
							break;
						case "@EventInput_Subject":
							input.value = $scope.subject;
							break;
						case "@Body":
							input.value = actionBody;
							break;
						case "@EventInput_BrowserNotify":
							input.value = $scope.browserNotify ? "True" : "False";
							break;
					}
				});

				if ($scope.action.key == "AddRecipientAction")
				{
					var res = await $http.post('~/api/v1/settings/forwardable-address/' + $scope.level, JSON.stringify({ emailAddresses: [$scope.action.inputs[0].value] }));
					if (!res.data.success) {
						if (res.data.message == "DOMAIN") {
							toaster.pop("error", $translate.instant("AUTOMATED_FORWARDING_DISABLED"));
						} else if (res.data.message == "USER") {
							toaster.pop("error", $translate.instant("AUTOMATED_FORWARDING_DISABLED"));
						} else {
							toaster.pop("error", $translate.instant("FORWARDS_TO_X_NOT_ALLOWED", { forwardingAddress: res.data.message }));
						}

						$scope.$applyAsync();
						return;
					}
				}

				$mdDialog.hide({ action: $scope.action, deleteAction: false });
			});
		}

		function deleteThis() {
			$mdDialog.hide({ action: $scope.action, deleteAction: true });
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function isDomainAdmin() {
			return coreData.user.isDomainAdmin;
		}
	}

})();
