(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsFailoverServersController", adminSettingsFailoverServersController);

	function adminSettingsFailoverServersController($rootScope, $scope, $mdDialog, $filter, $http, $compile,
		coreLicensing, NgTableParams, gridCheckboxes, errorHandling, i18n) {

		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { machineName: "asc" },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});

		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.isInitialized = false;
		vm.isEnterprise = coreLicensing.hasEnterpriseFunctionality;

		// Functions
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
		}

		function loadItems() {
			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(function (result) {
					vm.globalMailSettings = angular.copy(result.data.globalMailSettings);
					vm.machineName = vm.globalMailSettings.machineName || "";
					vm.failoverEnabled = vm.globalMailSettings.failoverEnabled || false;

					vm.items = vm.globalMailSettings.failoverClusterSettings ? (vm.globalMailSettings.failoverClusterSettings.failoverNodes || []) : [];
					vm.high = 0;
					angular.forEach(vm.items, function (card) {
						if (card.id == undefined)
							card.id = card.guid;
						var version = parseInt(card.version.replace(/\./g, ""));
						if (version > vm.high) vm.high = version;
						card.versionNum = version;
						if (card.machineName.localeCompare(vm.machineName) === 0) {
							card.status = $filter("translate")("ACTIVE");
							card.isActive = true;
						} else {
							card.status = $filter("translate")("INACTIVE");
							card.isActive = false;
						}
					});

					vm.isInitialized = true;

					vm.tableParams.settings({ dataset: vm.items });
					
					$rootScope.$broadcast("gateways:countUpdated");
					refreshTable();
				},
				function () {
					vm.isInitialized = false;
				});
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored)
				return;
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteItems", click: deleteItems, params: $scope.dropdownEvent, translateKey: "DELETE" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(event) {
			var checked = vm.checkboxes.getCheckedItems();
			if (!checked || !checked.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize("CONFIRMATIONS_DELETE_ITEMS", { count: checked.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				var grepped = $.grep(vm.items, function(value) { return !value.isActive && checked.indexOf(value.id) !== -1; });
				var toRemove = $.map(grepped, function (c) { return c.ipAddress; });
				if (toRemove.length < 1) return;
				var params = JSON.stringify({ ips: toRemove });

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/delete-failover-nodes", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					vm.checkboxes.reset();
					activate();
				}
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

	}

})();
