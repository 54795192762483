(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsPortsController", adminSettingsPortsController);

	function adminSettingsPortsController($rootScope, $scope, $mdDialog, $filter, $http, $compile,
		NgTableParams, gridCheckboxes, errorHandling, i18n) {
		var vm = this;
		vm.searchText = null;
		vm.ports = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { port: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.newItem = newItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.deleteItems = deleteItems;

		activate();

		///////////////////

		function activate() { }

		function queryData(params) {
			var skip = (params.page() - 1) * params.count();
			var take = Number(params.count());
			var search = vm.searchText ? vm.searchText.toLowerCase() : "";

			var sortField = null;
			var sortDescending = false;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortField = k;
				sortDescending = params.sorting()[k] === "desc";
				break;
			}

			$rootScope.spinner.show();
			return $http
				.get("~/api/v1/settings/sysadmin/ip-binding-manager/" + sortField + '/' + sortDescending)
				.then(onDataQuery, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDataQuery(result) {
				vm.bindingManager = result.data.bindingManager;
				vm.ports = result.data.bindingManager.bindingPorts || [];
				angular.forEach(vm.ports, function (card) {
					switch (card.type) {
						case 0: card.typeStr = $filter("translate")("SMTP"); break;
						case 1: card.typeStr = $filter("translate")("POP"); break;
						case 2: card.typeStr = $filter("translate")("IMAP"); break;
						case 3: card.typeStr = $filter("translate")("LDAP"); break;
						case 4: card.typeStr = $filter("translate")("SUBMISSION"); break;
						case 5: card.typeStr = $filter("translate")("XMPP"); break;
						default: card.typeStr = $filter("translate")("UNKNOWN_PORT_TYPE"); break;
					}
					fillIpsForPort(card);
				});
				vm.tableParams.total(vm.ports.length);
				if (sortField === 'ipCount') {
					vm.ports = $filter('orderBy')(vm.ports, sortField, sortDescending);
				}
				if (search) {
					vm.ports = _.filter(vm.ports, function (value) {
						if (value.port && value.port.toString().indexOf(search) !== -1)
							return true;
						if (value.name && value.name.toLowerCase().indexOf(search) !== -1)
							return true;
						return false;
					});
					vm.tableParams.total(vm.ports.length);
				}
				return vm.ports.slice(skip, skip + take);
			}
		}

		function fillIpsForPort(port, isForPopup) {
			var ipCount = 0;
			var ips = [];
			angular.forEach(vm.bindingManager.bindingInfo,
				function(ip) {
					var found = false;
					angular.forEach(ip.portIDs,
						function(boundPort) {
							if (boundPort == port.id) {
								ips.push({ ipAddress: ip.ipAddress, selected: true });
								ipCount++;
								found = true;
							}
						});
					if (!found && isForPopup) {
						ips.push({ ipAddress: ip.ipAddress, selected: false });
					}
				});
			port.ips = ips;
			port.ipCount = ipCount;
		}

		function newItem(ev) {
			var info = {
				isNew: true,
				id: undefined,
				name: "",
				port: undefined,
				type: 0,
				description: "",
				isTls: false,
				isSsl: false,
				certificatePath: "",
				password: "",
				ports: vm.ports
			};
			fillIpsForPort(info, true);
			openEditModal(info, ev)
				.then(
					function () {
						refreshTable();
						signalCountChanged();
					},
					function () { });
		}

		function editItem(card, ev) {
			if (!card) return;
			var info = angular.copy(card);
			info.password = "0b6874fa-d052-49bf-a3cb-c04ebb5c4909"; // This is a static password to conceal the real password
			info.ports = vm.ports;
			fillIpsForPort(info, true);
			openEditModal(info, ev).then(function (data) {
				if (data && data.delete) {
					confirmAndDelete([card.id], ev);
				}
				else {
					refreshTable();
				}

			}, function () { });
		}

		function openEditModal(info, ev) {
			return $mdDialog.show({
				locals: { info: info },
				controller: "systemPortsModalController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/bindings/port.dlg.html",
				targetEvent: ev
			});
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function searchItems() {
			refreshTable();
		}

		function deleteItems(ev) {
			var cards = vm.checkboxes.getCheckedItems();
			confirmAndDelete(cards, ev);
		}
		function confirmAndDelete(cards, ev) { 
			if (!angular.isArray(cards) || cards.length < 1) {
				return;
			}
			var confirm = $mdDialog.confirmDeletion()
				.textContent(i18n.reactPluralize('CONFIRMATIONS_DELETE_ITEMS', { count: cards.length }))
				.targetEvent(ev);
			$mdDialog.show(confirm).then(doDelete, function () { });

			function doDelete() {
				var ids = cards;
				var params = JSON.stringify({
					toRemove: ids
				});

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/sysadmin/ip-binding-ports", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					refreshTable();
					signalCountChanged();
				}
			}
		}

		function signalCountChanged() {
			$rootScope.$broadcast("bindings:counts-changed");
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
	}

})();
