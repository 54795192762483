(function(){
    "use strict";
    
    angular
        .module("smartertools")
        .service("i18n", i18n);
    
    function i18n($filter){
        const vm = this;
        vm.reactPluralize = reactPluralize;
        vm.reactPluralizeWithCommas = reactPluralizeWithCommas;
        
        // Functions
        activate();

        ////////////////////

        function activate() { }
        
        function reactPluralize(translationRoot, props){
            // If props is not an object, attempt to convert it to a number
            if (typeof props !== 'object') {
                const count = Number(props);
                if (!isNaN(count)) {
                    props = { count };
                }
            }
            
            const oneKey = translationRoot+"_one";
            const otherKey = translationRoot+"_other";
            return $filter("translate")(props.count === 1 ? oneKey : otherKey, props);
        }


        function reactPluralizeWithCommas(translationRoot, props){
            // If props is not an object, attempt to convert it to a number
            if (typeof props !== 'object') {
                const count = Number(props);
                if (!isNaN(count)) {
                    props = { count };
                }
            }

            const oneKey = translationRoot+"_one";
            const otherKey = translationRoot+"_other";
            
            // Copy props with formatted count
            let newProps = Object.assign({}, props);
            newProps.count = $filter('number')(props.count || 0);
            
            
            return $filter("translate")(props.count === 1 ? oneKey : otherKey, newProps);
        }
    }
    
    
    
    
})();