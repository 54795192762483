(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainEditAliasController", domainEditAliasController);

	function domainEditAliasController($rootScope, $scope, $mdDialog, $http, $filter, $q, coreLicensing, errorHandling, successHandling, emailValidationService,
        name, displayName, targets, allowSending, showInGal, showInXmpp, internalOnly, includeAllDomainUsers, isCatchAll, catchAllEnabled, description) {

		var vm = this;
		vm.arrayTargets = [];
		vm.oldName = name;
        $scope.name = name;
		$scope.isNew = !name;
		$scope.targets = targets.join("\n");
		$scope.invalidEmails = [];
		$scope.allowSending = allowSending;
		$scope.showInGal = showInGal;
		$scope.showInXmpp = showInXmpp;
		$scope.internalOnly = internalOnly;
		$scope.includeAllDomainUsers = includeAllDomainUsers;
		$scope.isCatchAll = isCatchAll;
		$scope.catchAllEnabled = catchAllEnabled;
		$scope.description = description;
		$scope.isValid = true;
        $scope.isEnterprise = false;
        $scope.displayName = displayName;

		// Functions
		$scope.save = save;
		$scope.cancel = function () { $mdDialog.cancel(); };
		$scope.getInvalidEmails = getInvalidEmails;
		$scope.fixInvalidEmail = fixInvalidEmail;
		$scope.removeInvalidEmail = removeInvalidEmail;
		$scope.delete = deleteItem;
		activate();

		///////////////////////////////

		function activate() {
			recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);
        }

        function recalculateLicense() {
            $scope.isEnterprise = coreLicensing.hasEnterpriseFunctionality;
            if (!$scope.isEnterprise) {
                $scope.showInXmpp = false;
            }
        }

		function save() {
			if (!($scope.isValid = validate()))
				return;

			var params = {
				alias: {
					name: $scope.name,
					displayName: $scope.displayName,
					aliasTargetList: vm.arrayTargets,
					allowSending: $scope.allowSending,
					hideFromGAL: !$scope.showInGal,
					enableForXmpp: $scope.showInXmpp,
					internalOnly: $scope.internalOnly,
					includeAllDomainUsers: $scope.includeAllDomainUsers,
					isCatchAll: $scope.catchAllEnabled ? $scope.isCatchAll : false,
					description: $scope.description
				}
			};

			if ($scope.isNew) {
				$http.post("~/api/v1/settings/domain/alias-put/", params).then(closeAndReturnData, errorHandling.report);
			} else {
				params.oldName = vm.oldName;
				$http.post("~/api/v1/settings/domain/alias/", params).then(closeAndReturnData, errorHandling.report);
			}

			function closeAndReturnData() {
				$mdDialog.hide({
					name: $scope.name,
					targets: vm.arrayTargets,
					showInGal: $scope.showInGal,
					showInXmpp: $scope.showInXmpp,
					internalOnly: $scope.internalOnly,
					includeAllDomainUsers: $scope.includeAllDomainUsers,
					isCatchAll: $scope.isCatchAll,
					description: $scope.description
				});
			}
		}

		function validate() {
			var areTargetsValid;

			if ($scope.targets && $scope.targets.length > 0) {
				vm.arrayTargets = $scope.targets.split("\n");

				areTargetsValid = true;
				for (var i = 0; i < vm.arrayTargets.length; i++) {
					if (!emailValidationService.isValidEmail(vm.arrayTargets[i])) {
						$scope.areTargetsValid = false;
						break;
					}
				}
			}
			else 
				areTargetsValid = $scope.includeAllDomainUsers;

			var isNameValid = $scope.name && $scope.name.length > 0 && $scope.name.indexOf("@") === -1;
			return areTargetsValid && isNameValid;
		}

		function getInvalidEmails(userForm) {
			$scope.invalidEmails = [];
			$scope.targets = userForm.emails.$viewValue;

			var emails = $scope.targets.split("\n");

			for (var i = 0; i < emails.length; i++) {
				if (!emails[i] && i === emails.length - 1) continue;
				if (!emailValidationService.isValidEmail(emails[i]))
					$scope.invalidEmails.push({ invalidEmailIndex: i, emailToFix: emails[i] });
			}
		}

		function fixInvalidEmail(userForm, email) {
			var emails = $scope.targets.split("\n");

			emails[email.invalidEmailIndex] = email.emailToFix;

			$scope.targets = emails.join("\n");
			userForm.emails.$viewValue = $scope.targets;
			userForm.emails.$modelValue = $scope.targets;
			userForm.emails.$commitViewValue();
			userForm.emails.$rollbackViewValue();

			getInvalidEmails(userForm);
		}

		function removeInvalidEmail(userForm, email) {
			var emails = $scope.targets.split("\n");

			emails.splice(email.invalidEmailIndex, 1);

			$scope.targets = emails.join("\n");
			userForm.emails.$viewValue = $scope.targets;
			userForm.emails.$modelValue = $scope.targets;
			userForm.emails.$commitViewValue();
			userForm.emails.$rollbackViewValue();

			getInvalidEmails(userForm);
		}

		function deleteItem(ev) {
			if ($scope.isNew)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ALIASES_one"))
				.targetEvent(ev);

			$mdDialog
				.show(confirm)
				.then(doDelete, function () { });

			function doDelete() {
				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/domain/aliases-delete", JSON.stringify({ input: [name] }))
					.then(onDeleteCompleted, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDeleteCompleted(success) {
				if (success && success.data.result) {
					var confirm = $mdDialog.confirmDeletion()
						.title()
						.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE_ACCOUNTS"))
						.ok($filter("translate")("OK"))
						.noWarn(true)
						.hideCancel(true);
					$mdDialog.show(confirm);
				}

				successHandling.report();
				$rootScope.$broadcast("refreshUsersGrid");
				$mdDialog.hide();
			}
		}
	}

})();
