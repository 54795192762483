(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userSettingsSharingController", userSettingsSharingController);

    function userSettingsSharingController($scope, $rootScope, $http, $q, $mdDialog, $compile, $translate, coreData, userDataService, NgTableParams, gridCheckboxes, errorMessageService, errorHandling, i18n) {
        var vm = this;
        vm.domain = userDataService.user.domain;
        vm.myUsername = coreData.user.username;
        vm.isInitialized = true;
        vm.selectedSection = "SHARED_WITH_ME";
        vm.gridSections = [
            { translate: "SHARED_WITH_ME", length: '0' },
            { translate: "SHARED_WITH_OTHERS", length: '0' },
            { translate: "DELEGATION", length: '0' },
        ];
        vm.myUnsharedFolders = [];

        // Functions
        vm.attachItems = attachItems;
        vm.changeSection = function (section) { vm.selectedSection = section; refresh(); };
        vm.deleteItems = deleteItems;
        vm.detachItems = detachItems;
        vm.editMyVirtualFolder = editMyVirtualFolder;
        vm.editOtherVirtualFolder = editOtherVirtualFolder;
        vm.newShare = newShare;
        vm.refresh = refresh;
        vm.removeDelegates = removeDelegates;
        vm.contextMenuGridItem = contextMenuGridItem;

        // Events
        $scope.$on('signalR.mailHub.client.sharesChanged', function () { refresh(); });
        activate();

        /////////////////////

        function activate() {
            vm.sharedWithMeTableParams = new NgTableParams(
                {
                    sorting: { ownerUserName: 'asc' },
                    count: 25
                },
                {
                    dataset: [],
                    counts: $rootScope.commonTableCounts
                });

            vm.sharedWithOthersTableParams = new NgTableParams(
                {
                    sorting: { folderNameSort: 'asc' },
                    count: 25
                },
                {
                    dataset: [],
                    counts: $rootScope.commonTableCounts
                });
            vm.delegationTableParams = new NgTableParams(
                {
                    sorting: { delegateNameSort: 'asc' },
                    count: 25
                },
                {
                    dataset: [],
                    counts: $rootScope.commonTableCounts
                });
            refresh();
        }

        function refresh() {
            $q.all([
                $http.post('~/api/v1/folders/list-all-virtual-folders'),
                $http.post('~/api/v1/folders/list-owned-virtual-folders'),
                $http.post("~/api/v1/folders/list-owned-unshared-folders"),
                $http.get("~/api/v1/settings/delegation")
			]).then(onDataRetrieved, function () {});

            function onDataRetrieved(results) {
 

                var allVirtualFolders = results[0].data.virtualFolders;
                if (allVirtualFolders) {
                    for (var i = 0; i < allVirtualFolders.length; i++) {
                        allVirtualFolders[i].folderNameSort = allVirtualFolders[i].folderPath || allVirtualFolders[i].folderName;
                        allVirtualFolders[i].isMappedSortable = allVirtualFolders[i].isMapped ? 1 : 0;
                    }
                }

                vm.gridSections[0].length = allVirtualFolders && allVirtualFolders.length;
                vm.sharedWithMeTableParams.settings({ dataset: allVirtualFolders });
                vm.sharedWithMeTableParams.reload();

                var ownedVirtualFolders = results[1].data.virtualFolders;
                if (ownedVirtualFolders) {
                    for (var i = 0; i < ownedVirtualFolders.length; i++) {
                        ownedVirtualFolders[i].folderNameSort = ownedVirtualFolders[i].folderPath || ownedVirtualFolders[i].folderName;
                    }
                }

                vm.gridSections[1].length = ownedVirtualFolders && ownedVirtualFolders.length;
                vm.sharedWithOthersTableParams.settings({ dataset: ownedVirtualFolders });
                vm.sharedWithOthersTableParams.reload();

                vm.myUnsharedFolders = results[2].data.virtualFolders;

                var delegatedAccounts = results[3].data.delegateAccounts;
                if (delegatedAccounts) {
                    for (var i = 0; i < delegatedAccounts.length; i++) {
                        delegatedAccounts[i].delegateNameSort = delegatedAccounts[i].username || delegatedAccounts[i].displayName;
                    }
                }
                vm.gridSections[2].length = delegatedAccounts && delegatedAccounts.length;
                vm.delegationTableParams.settings({ dataset: delegatedAccounts });
                vm.delegationTableParams.reload();

                switch (vm.selectedSection) {
                    case "SHARED_WITH_ME":
                        vm.checkboxes = gridCheckboxes.init("folderId");
                        vm.checkboxes.table = vm.sharedWithMeTableParams;
                        break;
                    case "SHARED_WITH_OTHERS":
                        vm.checkboxes = gridCheckboxes.init("folderId");
                        vm.checkboxes.table = vm.sharedWithOthersTableParams;

                        break;
                    case "DELEGATION":
                        vm.checkboxes = gridCheckboxes.init("email");
                        vm.checkboxes.table = vm.delegationTableParams;
                        break;
                }

            }
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if (!items.some((val) => vm.selectedSection === "DELEGATION" ? val === item.email : val === item.folderId.toString())) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = vm.selectedSection === "DELEGATION" ? [item.email] : [item.folderId];
            }

            var selected = {};
            for (var i = 0; i < items.length; ++i) {
                selected[items[i]] = true;
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [];

            if (vm.selectedSection === "DELEGATION") {
                $scope.dropdownOptions.push({ key: 'remove', click: removeDelegates, params: selected, translateKey: 'REMOVE' });
            } 
            else if (vm.selectedSection === "SHARED_WITH_ME") {
                $scope.dropdownOptions.push({ key: 'attach', click: attachItems, params: selected, translateKey: 'ATTACH' });
                $scope.dropdownOptions.push({ key: 'detach', click: detachItems, params: selected, translateKey: 'DETACH' });
            }
            else {
                $scope.dropdownOptions.push({ key: 'stopSharing', click: deleteItems, params: { items: selected, event: ev}, translateKey: 'STOP_SHARING' });
            }

            if (!$scope.dropdownOptions.length)
                return;

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element)
                element.append($compile(elementToCompile)($scope));
        }

        function newShare(ev) {
            $mdDialog.show({
                controller: "newSharedFolderDialogController",
                controllerAs: "ctrl",
                templateUrl: 'app/shared/modals/new-shared-folder.dlg.html',
                targetEvent: ev,
                locals: { unsharedFolders: vm.myUnsharedFolders },
                clickOutsideToClose: false
            })
                .then(function (params) {
                    refresh();
                    $rootScope.spinner.hide();
                }, function (cancel) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }

        function deleteItems(items, event) {
            var folderIds = getCheckedItems();
            if (folderIds == null || folderIds.length == 0)
                return;
            $rootScope.spinner.show();
            $http.post("~/api/v1/folders/stop-sharing-folders", JSON.stringify({ folderIds: folderIds }))
                .then(function (success) {
                    refresh();
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }
        function removeDelegates(items, event) {
            var users = getCheckedItems();
            if (!users || users.length === 0)
                return;
            var confirm = $mdDialog.confirmDeletion()
                .textContent(i18n.reactPluralize("CONFIRMATIONS_REMOVE_DELEGATES", { count: users.length }))
                .targetEvent(event);
            $mdDialog
                .show(confirm)
                .then(onConfirmed, function () { });

            function onConfirmed() {
                var params = {
                    input: users
                };
                $rootScope.spinner.show();
                $http
                    .post('~/api/v1/settings/delegation-remove', JSON.stringify(params))
                    .then(function () {
                        refresh();
                    }, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function attachItems(items, event) {
            attachFolderIds(getCheckedItems());
        }

        function attachFolderIds(folderIds) {
            if (folderIds == null || folderIds.length == 0)
                return;
            $rootScope.spinner.show();
            $http.post("~/api/v1/folders/attach-to-virtual-folders", JSON.stringify({ folderIds: folderIds }))
                .then(function (success) {
                    refresh();
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }

        function detachItems(items, event) {
            detachFolderIds(getCheckedItems());
        }

        function detachFolderIds(folderIds) {
            if (folderIds == null || folderIds.length == 0)
                return;
            $rootScope.spinner.show();
            $http.post("~/api/v1/folders/detach-from-virtual-folders", JSON.stringify({ folderIds: folderIds }))
                .then(function (success) {
                    refresh();
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }

        function editMyVirtualFolder(selectedCard, event) {
            $mdDialog
                .show({
                    controller: "editOwnShareDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-own-share.dlg.html",
                    targetEvent: event,
                    locals: { folder: selectedCard, folderId: null }
                })
                .then(refresh, function() {});
        };

        function editOtherVirtualFolder(selectedCard, event) {
            $mdDialog
                .show({
                    controller: "editSharedFolderDialogController",
                    controllerAs: "ctrl",
                    templateUrl: "app/shared/modals/edit-shared-folder.dlg.html",
                    targetEvent: event,
                    locals: { folder: selectedCard, folderId: null, webcalLink: null }
                })
                .then(function (modalSuccess) {
                    refresh();
                });
        };

        function getCheckedItems() {
            var retVal = [];
            for (var k in vm.checkboxes.items) {
                if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k])
                    retVal.push(k);
            }
            return retVal;
        }
    };
})();