(function () {
    "use strict";
    angular
        .module("smartermail")
        .controller("aiCompose",
            function ($scope, $mdDialog, errorHandling, $window, text, highlight, $translate, successHandling, $http, $rootScope) {
                const vm = this;
                vm.initialized = false;
                vm.text = text;
                vm.highlight = highlight;
                vm.getAiInstructions = getAiInstructions;
                vm.aiInstructions = "";
                vm.selectedCommand = vm.highlight ? "proofread" : "custom";
                if (vm.selectedCommand !== "custom") {
                    vm.getAiInstructions(vm.selectedCommand);
                }
                vm.aiInput = highlight || text;
                vm.generatedResponse = "";
                vm.initialized = true;
                vm.generateResponse = generateResponse;


                function getAiInstructions(selectedCommand = vm.selectedCommand) {
                    switch (selectedCommand) {
                        case "translate":
                            vm.aiInstructions = $translate.instant("AI_TRANSLATE_INSTRUCTIONS");
                            break;
                        case "tone":
                            vm.aiInstructions = $translate.instant("AI_TONE_INSTRUCTIONS");
                            break;
                        case "proofread":
                            vm.aiInstructions = $translate.instant("AI_PROOFREAD_INSTRUCTIONS");
                            break;
                        case "shorten":
                            vm.aiInstructions = $translate.instant("AI_SHORTEN_INSTRUCTIONS");
                            break;
                        case "lengthen":
                            vm.aiInstructions = $translate.instant("AI_LENGTHEN_INSTRUCTIONS");
                            break;
                        default:
                            vm.aiInstructions = "";
                            break;
                    }
                    return vm.aiInstructions;
                }


                async function generateResponse() {
                    try {
                        const inputText = vm.aiInput?.trim();
                        var command = ""
                        if (vm.selectedCommand === "custom") {
                            vm.aiInstructions = "";
                        } else {
                            command = vm.aiInstructions?.trim();
                        }

                        if (vm.selectedCommand !== "custom") {
                            if (!command || !inputText) {
                                await errorHandling.report($translate.instant("PLEASE_PROVIDE_COMMAND_AND_INPUT"));
                                return;
                            }
                        }

                        const payload = {command: `${command} ${inputText}`};
                        $rootScope.spinner.show();
                        const response = await $http.post("api/v1/ai/GetAiResponseAsync", payload);
                        vm.generatedResponse = response?.data;

                        $rootScope.$applyAsync(() => {
                            $rootScope.spinner.hide();
                        });

                    } catch (error) {
                        console.error("An error occurred while generating the response:", error);
                        await errorHandling.report(error);

                        $rootScope.$applyAsync(() => {
                            $rootScope.spinner.hide();
                        });
                    }
                }

                vm.insertResponse = function () {
                    if (vm.generatedResponse.trim() !== "") {
                        $mdDialog.hide(vm.generatedResponse);
                    } else {
                        const errorTrans = $translate.instant("AI_NO_RESPONSE_TO_INSERT");
                        errorHandling.report(errorTrans);
                    }
                }

                vm.closeDialog = function () {
                    $mdDialog.hide();
                };
            });
})();