(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("notesViewController", notesViewController);

    function notesViewController($rootScope, $scope, $mdDialog, $timeout, $filter, $stateParams, $q, $http, $translate, $sce, coreData, coreDataNotes,
        coreDataCategories, userDataService, authStorage, errorHandling, apiCategories, apiNoteSources, errorMessageService, themesService, htmlSandboxing, fileInfoProvider, toaster, i18n) {

        var vm = this;
        var askBeforeLeave = true;
        $scope.isNew = $stateParams.id === undefined;
        $scope.isLoaded = false;
        $scope.editingItem = true;
        $scope.editorScope = {};
        $scope.sources = [];
        $scope.notes = [];
        $scope.showCategories = true;
        vm.removedAttachedFiles = [];

        // Functions
        $scope.cancel = leavePage;
        $scope.deleteDropdown = deleteDropdown;
        $scope.deleteWithConfirmation = deleteWithConfirmation;
        $scope.isOwner = isOwner;
        $scope.onSourceChanged = onSourceChanged;
        $scope.save = onSave;
        $scope.setForm = setForm;
        $scope.setFormDirty = setFormDirty;
        $scope.trustDescriptionHtml = trustDescriptionHtml;
        vm.categoryColorByName = categoryColorByName;
        vm.deletePromise = deletePromise;
        vm.hasSelectedCategory = hasSelectedCategory;
        vm.onCategoryChanged = onCategoryChanged;
        vm.onClearAllCategories = onClearAllCategories;
        vm.openManageCategoriesModal = openManageCategoriesModal;
        vm.removeAttachedFile = removeAttachedFile;

        activate();

        ////////////////////////

        function activate() {
            $scope.afContext = "user";
            $scope.$on('$destroy', destroy);

            themesService.ensureActivated();
            $rootScope.spinner.showInstant();

            var promises = [
                apiNoteSources.getSources(true),
                coreData.init()
            ];
            if (!$scope.isNew)
                promises.push(coreDataNotes.getNote($stateParams.id, $stateParams.sourceId, $stateParams.sourceOwner));

            $q.all(promises)
                .then(function (results) {
                    $scope.sources = results[0];

                    $scope.defaultSource = $scope.sources.find(source => source.isPrimary && source.ownerUsername === userDataService.user.username);
                    if (!$scope.isNew) {
                        $scope.currentSource = $scope.sources.find(source => source.itemID === $stateParams.sourceId);
                        $scope.noteInfo = $.extend(true, results[2],
                            {
                                sourcePermission: $scope.currentSource ? $scope.currentSource.access : $stateParams.sourceOwner === userDataService.user.username ? 8 : 4,
                                sourceOwner: $scope.currentSource ? $scope.currentSource.ownerUsername : $stateParams.sourceOwner
                            });
                    }
                    init();
                }, errorHandling.reportAndHideSpinner);

            userDataService.init();

            window.onbeforeunload = onWindowBeforeUnload;

            $scope.$on("categoriesUpdated", onCategoryListModified);
            $scope.$watch('noteInfo.text', onNoteInfoTextChanged);
            $scope.$watch("currentSource", onCurrentSourceChanged);
        }

        function init() {
            var popoutData = authStorage.getPopoutData();
            if (!$.isEmptyObject(popoutData)) {
                $scope.noteInfo = undefined;
                $scope.noteInfo = popoutData.noteInfo;
                $scope.originalNoteDesc = $scope.noteInfo.text;
                $scope.currentSource = popoutData.currentSource || $scope.defaultSource;
                $scope.isNew = popoutData.isNew;
            } else if (!$scope.isNew) {
                setupEditNote();
            } else { // new
                $scope.currentSource = $scope.defaultSource;
                // If a user starts typing before we get here then their text will be erased, so we check for that
                if (!$scope.noteInfo) $scope.noteInfo = {};
                $scope.noteInfo.color = 'white';
                $scope.noteInfo.sourcePermission = 8;
            }
            if ($scope.currentSource.ownerUsername) {
                apiCategories.categoryOwner = $scope.currentSource.ownerUsername;
            }

            vm.attachedFiles = $scope.noteInfo.attachedFiles ? JSON.parse(JSON.stringify($scope.noteInfo.attachedFiles)) : [];

            vm.attachmentGuid = coreData.generateGuid();
            vm.filesUploading = 0;
            $scope.noteCategories = $scope.currentSource.ownerUsername ? apiCategories.getCategories() : [];
            setupCategories($scope.noteCategories);
            $scope.isLoaded = true;
            $rootScope.spinner.hide();

            $timeout(function () {
                if (vm.userForm) {
                    vm.userForm.$setPristine();
                    vm.userForm.$setUntouched();
                }
            });
        }

        function destroy() {
            window.onbeforeunload = undefined;
        }

        function hasSelectedCategory() {
            if (!$scope.noteCategories)
                return false;
            for (var i = 0; i < $scope.noteCategories.length; i++)
                if ($scope.noteCategories[i].selected) return true;
            return false;
        }

        function onCategoryChanged(cat) {
            if (cat)
                cat.selected = !cat.selected;
            vm.userForm.$setDirty();
        }

        function onClearAllCategories() {
            for (var i = 0; i < $scope.noteCategories.length; i++)
                $scope.noteCategories[i].selected = false;
            vm.userForm.$setDirty();
        }

        function openManageCategoriesModal(ev) {
            $mdDialog.show({
                controller: "manageCategoriesDialogController",
                controllerAs: "manageCategoriesCtrl",
                templateUrl: "app/shared/modals/manage.categories.dlg.html",
                targetEvent: ev
            });
        }

        function categoryColorByName(catName) {
            const cat = getCategoryByName(catName);
            if (!cat || cat.colorIndex == -1)
                return null;
            const color = apiCategories.getCategoryColor(cat.colorIndex);
            if (!color || !color.rgb)
                return null;
            return color.rgb;

            function getCategoryByName(categoryName) {
                if (!categoryName) return null;
                var results = $.grep($scope.noteCategories, (cat) => cat.name.toUpperCase() === categoryName.toUpperCase());
                return results.length > 0 ? results[0] : null;
            }
        }

        //// Edit
        function setupEditNote() {
            $scope.isNew = false;

            var text = $scope.noteInfo.text;
            if ($scope.noteInfo.isPlainText)
                text = text.replace(/\r\n/g, "<br>");

            // Check for a wrapper div. If it is present, remove it to prevent issues with paragraph styling.
            try {
                var $content = $(text);
                if ($content.length === 1 &&
                    $content[0].nodeName.toUpperCase() === "DIV" &&
                    $content.children("div,h1,h2,h3,h4,pre,blockquote,ul,ol,p").length > 0)
                    text = $content.html();
            } catch (err) {
                // Ignore
            }
            $scope.noteInfo.text = text;

            $scope.originalNoteDesc = $scope.noteInfo.text;
            $timeout(function () {
                $('[name="subject"]').trigger("focus");
                if (vm.userForm) {
                    vm.userForm.$setPristine();
                    vm.userForm.$setUntouched();
                }
            });
        }

        //// Save
        function onSave() {
            $scope.noteInfo.text = $scope.editorScope.getHtml();

            $timeout(function () {
                $scope.noteInfo.categoriesMetaData = $.grep($scope.noteCategories, function (cat) { return cat.selected; });
                if (!$scope.noteInfo.isPlainText && $scope.noteInfo.text.indexOf("\"/attachedfile?data=") > -1) {
                    $scope.noteInfo.text = $scope.noteInfo.text.split("\"/attachedfile?data=").join("\"attlinkedfileid:");
                }
                var modNote;
                if (vm.attachedFiles.some(x => x.isNew)) {
                    $scope.noteInfo.attachmentGuid = vm.attachmentGuid;
                }
                if (!$scope.noteInfo.subject) {
                    $scope.noteInfo.subject = $filter("htmlUnescape")($($scope.noteInfo.text)[0].innerText.substring(0, 255));
                }
                if ($scope.isNew) {
                    $scope.noteInfo.source = $scope.currentSource;
                    $scope.noteInfo.sourceId = $scope.currentSource.itemID;
                    $scope.noteInfo.sourceOwner = $scope.currentSource.ownerUsername;

                    modNote = coreDataNotes.addNote;
                }
                else if ($scope.noteInfo.sourceId !== $scope.currentSource.itemID) {
                    const origNoteId = $scope.noteInfo.id;
                    const origNoteItemId = $scope.noteInfo.itemId;
                    const origSourceId = $scope.noteInfo.sourceId;
                    const origSourceOwner = $scope.noteInfo.sourceOwner;

                    $scope.noteInfo.id = undefined;
                    $scope.noteInfo.source = $scope.currentSource;
                    $scope.noteInfo.sourceId = $scope.currentSource.itemID;
                    $scope.noteInfo.sourceOwner = $scope.currentSource.ownerUsername;
                    $scope.noteInfo.oldSourceId = origSourceId;
                    $scope.noteInfo.oldId = origNoteId;
                    $scope.noteInfo.oldSourceOwner = origSourceOwner;

                    deleteRemovedAttachedFiles($scope.noteInfo.sourceId, $scope.noteInfo.sourceOwner)
                        .then(
                            function () {
                                coreDataNotes.addNote($scope.noteInfo)
                                    .then(
                                        function (newNote) {
                                            $scope.noteInfo.itemId = newNote.itemId;

                                            moveAttachedFiles(origSourceId, $scope.currentSource.itemID, origNoteItemId, newNote.itemId)
                                                .then(
                                                    function () {
                                                        coreDataNotes.removeSingleNote(origSourceOwner, origSourceId, origNoteId)
                                                            .then(leavePage,
                                                                function (failure) {
                                                                    $rootScope.spinner.hide();
                                                                    errorMessageService.showErrorMessage(failure);
                                                                });
                                                    },
                                                    function (failure) {
                                                        $rootScope.spinner.hide();
                                                        errorMessageService.showErrorMessage(failure);
                                                    });
                                        },
                                        function (failure) {
                                            $rootScope.spinner.hide();
                                            errorMessageService.showErrorMessage(failure);
                                        });

                            },
                            errorMessageService.showErrorMessage);
                }
                else {
                    modNote = coreDataNotes.editNote;
                }

                if (typeof modNote === 'function') {
                    deleteRemovedAttachedFiles($scope.noteInfo.sourceId, $scope.noteInfo.sourceOwner)
                        .then(() => modNote($scope.noteInfo))
                        .then(leavePage, errorMessageService.showErrorMessage)
                        .finally($rootScope.spinner.hide);
                }
            });

            function moveAttachedFiles(oldSourceId, newSourceId, oldItemId, newItemId) {
                if (!vm.attachedFiles || vm.attachedFiles.length === 0) {
                    return $q.when();
                }
                const oldSource = $scope.sources.filter(src => src.itemID === oldSourceId)[0];
                const newSource = $scope.sources.filter(src => src.itemID === newSourceId)[0];
                const moveParams = JSON.stringify({
                    oldOwner: oldSource.ownerUsername,
                    newOwner: newSource.ownerUsername,
                    oldFolderId: oldSource.folderId,
                    oldItemId: oldItemId,
                    newFolderId: newSource.folderId,
                    newItemId: newItemId
                });

                return $http.post("~/api/v1/filestorage/move-attached-files", moveParams);
            }
        }

        //// Delete
        function deleteDropdown(params) {
            $scope.deleteWithConfirmation(params.card, params.event);
        }

        function deleteWithConfirmation(ev) {
            try {
                var confirm = $mdDialog.confirmDeletion()
                    .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS_one'))
                    .targetEvent(ev);
                $mdDialog.show(confirm)
                    .then(function () { vm.deletePromise(); }, null);
            }
            catch (err) {
                errorHandling.report(err.message);
                $rootScope.spinner.hide();
            }
        }

        function deletePromise() {
            $rootScope.spinner.show();
            coreDataNotes.removeNotes([$scope.noteInfo])
                .then(function (success) {
                    leavePage();
                    $rootScope.spinner.hide();
                }, function (failure) {
                    $rootScope.spinner.hide();
                    errorMessageService.showErrorMessage(failure);
                });
        }

        //// Cancel
        function leavePage() {
            $scope.skipNavigateConfirm = true;
            askBeforeLeave = false;
            window.close();
        }

        //// Category Functions
		function setupCategories(categories) {
            categories.forEach(function (cat) { cat.selected = false; });

            if ($scope.noteInfo.categoriesMetaData) {
                categories = $scope.noteInfo.categoriesMetaData.reduce(function (res, cat) {
                    const masterCat = res.find(c => c.name === cat.name);
                    if (masterCat) {
                        masterCat.selected = cat.selected;
                    } else if (cat.selected) {
                        res.push({ name: cat.name, colorIndex: -1, master: false, translatedName: $filter("translate")(cat.name), selected: true });
                    }
                    return res;
                }, 
                categories);

            }

            $scope.noteInfo.categoriesMetaData = categories;
		}


        function onCategoryListModified() {
			try {
				$scope.noteCategories = $scope.currentSource.ownerUsername ? apiCategories.getCategories() : [];
				setupCategories($scope.noteCategories);
			} catch (err) {
				errorHandling.report(err);
			}
        }

        function onSourceChanged(source) {
            $scope.currentSource = source;
            if ($scope.currentSource.ownerUsername !== apiCategories.categoryOwner) {
                apiCategories.categoryOwner = $scope.currentSource.ownerUsername;
                onCategoryListModified();
            }
            vm.userForm.$setDirty();
        }

        //// Other Functions
        function onNoteInfoTextChanged() {
            if ($scope.noteInfo && $scope.editingItem && $scope.noteInfo.text != $scope.originalNoteDesc) {
                vm.userForm.$setDirty();
            }
        }

        function setForm(form) {
            vm.userForm = form;
        }

        function setFormDirty(form) {
            form.$setDirty();
            $scope.$applyAsync();
        }

        function isOwner() {
            return $scope.currentSource && $scope.currentSource.ownerUsername === userDataService.user.username;
        }

        function onWindowBeforeUnload() {
            if ($scope.userForm.$dirty && askBeforeLeave) {
                askBeforeLeave = false;
                return "";
            }
        }

        function removeAttachedFile(attachment, ev) {
            if (!vm.canEdit && !attachment) return;
            if (attachment.isNew && typeof attachment.remove === "function") {
                attachment.remove();
                return;
            } 
            vm.removedAttachedFiles.push(attachment.fileReference);
            vm.userForm.$setDirty();
        }

        function deleteRemovedAttachedFiles(sourceId, ownerEmail) {
            if (!vm.removedAttachedFiles || vm.removedAttachedFiles.length === 0)
                return $q.resolve();
            var source = $scope.sources.filter(src => src.itemID === sourceId)[0];
            var removeParams = JSON.stringify({
                folderId: source.folderId,
                owner: ownerEmail,
                removeFileReferences: vm.removedAttachedFiles
            });
            return $http.post("~/api/v1/filestorage/remove-attached-file", removeParams);
        }

        function onCurrentSourceChanged(newvalue, oldvalue) {
            if (newvalue === oldvalue) return;
            if (newvalue.isDomainResource)
                $scope.afContext = "domainshare";
            else
                $scope.afContext = "user";
        }

        function trustDescriptionHtml (htmlText) {
			// Use this here as the content is sanitized by the server and ng-bind-html strips inline styling.
			return $sce.trustAsHtml(htmlSandboxing.parseCss(htmlText));
		}

        vm.fileUploadedCallback = function() {
            vm.userForm.$setDirty();
        }
    }
})();
